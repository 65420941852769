import { Button, Image } from "antd";
import { useAssets } from "../../../hooks";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import access from "../../../assets/access";
import Cookies from "js-cookie";
import full from "../../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
const ServiceCard = ({
  service,
  buttonType,
  setCurrent,
  setIsOpen,
  isPurchase,
  setIsPurchase,
  isMobile,
  allService,
  url,
}) => {
  const { getFile } = useAssets();
  const myservice = isPurchase ? service.service : service;
  const path = useLocation().pathname;
  const navigator = useNavigate();

  const types = !isPurchase
    ? myservice.types
        .map((t, i) => {
          if (i !== 0) return " " + t.serviceType.title;
          else return t.serviceType.title;
        })
        .toString()
    : allService.filter((s) => {
        return s.id == myservice.id;
      })[0]
    ? allService
        .filter((s) => {
          return s.id == myservice.id;
        })[0]
        .types.map((t, i) => {
          if (i !== 0) return " " + t.serviceType.title;
          else return t.serviceType.title;
        })
        .toString()
    : "";

  return (
    <div id={service.id} className="servic-card d-flex flex-column">
      {/* <Image
        style={
          isMobile
            ? { height: "120px", objectFit: "cover" }
            : { height: "150px", objectFit: "cover" }
        }
        placeholder={
          <Image
            style={
              isMobile
                ? { height: "120px", objectFit: "cover" }
                : { height: "150px", objectFit: "cover" }
            }
            preview={false}
            src={getFile("noImg")}
          />
        }
        src={myservice.cover ? url + "/" + myservice.cover : getFile("noImg")}
      /> */}

      <LazyLoadImage
        effect="blur"
        style={
          isMobile
            ? { height: "120px", objectFit: "cover" }
            : { height: "150px", objectFit: "cover" }
        }
        width={"100%"}
        placeholderSrc={getFile("noImg")}
        src={myservice.cover ? url + "/" + myservice.cover : getFile("noImg")}
      />
      <div className={`px-4 d-flex flex-column gap-2 py-3`}>
        <div className="d-flex flex-row ">
          <h4
            style={
              isMobile
                ? { width: "80%", fontSize: "14px", marginBottom: "0px" }
                : { width: "92%" }
            }
          >
            {types.length > 23 ? types.substr(0, 21) + "..." : types}
          </h4>
          <div
            dir="rtl"
            style={
              isMobile ? { width: "20%", marginLeft: "20px" } : { width: "8%" }
            }
          >
            {/* <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 11.5C22 5.70193 17.2981 1 11.5 1C5.70193 1 1 5.70193 1 11.5C1 17.2981 5.70193 22 11.5 22C17.2981 22 22 17.2981 22 11.5Z"
                stroke="rgba(46, 108, 223, 1)"
              />
              <path
                d="M14.3441 16.2188C14.2849 16.1342 14.1748 16.1003 14.0775 16.1469C13.0364 16.6378 11.8556 17.1584 11.6228 17.2049C11.6186 17.2007 11.6059 17.1922 11.5974 17.1753C11.5678 17.133 11.5509 17.0779 11.5509 17.006C11.5509 16.4177 11.9953 14.6275 12.8713 11.6862C13.612 9.21458 13.6966 8.70249 13.6966 8.5332C13.6966 8.27081 13.595 8.05074 13.4046 7.89415C13.2226 7.74602 12.9729 7.66984 12.6555 7.66984C12.1265 7.66984 11.517 7.86876 10.7891 8.28351C10.0823 8.68133 9.29091 9.35848 8.44448 10.2938C8.37677 10.3657 8.37253 10.4758 8.42755 10.5562C8.48257 10.6366 8.58837 10.6662 8.68148 10.6324C8.97773 10.5096 10.4759 9.89596 10.696 9.76053C10.8737 9.6505 11.0303 9.59125 11.1573 9.59125C11.1615 9.59125 11.1658 9.59125 11.17 9.59125C11.17 9.59971 11.1742 9.61241 11.1742 9.62934C11.1742 9.7563 11.1488 9.91289 11.0938 10.0822C9.81993 14.2128 9.19781 16.7478 9.19781 17.827C9.19781 18.2079 9.30361 18.5126 9.51099 18.737C9.72259 18.9655 10.0104 19.0798 10.3617 19.0798C10.7383 19.0798 11.1954 18.9232 11.7625 18.5973C12.3084 18.2841 13.1464 17.5943 14.3187 16.4939C14.3907 16.4177 14.4034 16.3035 14.3441 16.2188ZM14.3018 4.24602C14.0944 4.03441 13.8278 3.92438 13.5104 3.92438C13.1168 3.92438 12.7698 4.08097 12.4862 4.38992C12.2069 4.69463 12.0672 5.06283 12.0672 5.49451C12.0672 5.83308 12.173 6.11664 12.3762 6.33248C12.5835 6.55255 12.8459 6.66259 13.1591 6.66259C13.54 6.66259 13.8786 6.49753 14.1748 6.17166C14.4669 5.85001 14.615 5.47758 14.615 5.05437C14.615 4.72849 14.5092 4.45763 14.3018 4.24602Z"
                fill="rgba(46, 108, 223, 1)"
              />
            </svg> */}
          </div>
        </div>
        <h2 style={isMobile ? { fontSize: "16px", margin: 0, padding: 0 } : {}}>
          {myservice.title}
        </h2>
        {myservice.activeDays && (
          <div className="d-flex flex-row gap-2 align-items-center">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 6.66713e-05C3.58969 6.66713e-05 0 3.58974 0 8.00003C0 12.4103 3.58969 16 8 16C12.4103 16 16 12.4103 16 8.00003C16 5.24023 14.5986 2.80016 12.4661 1.36204L13.2305 0.381575L10 0.264389L10.9323 3.33339L11.6406 2.42323C13.2849 3.49817 14.4262 5.27285 14.6302 7.33467C14.46 7.34399 14.2999 7.41815 14.1826 7.5419C14.0654 7.66564 14.0001 7.82959 14 8.00003C14.0001 8.17112 14.066 8.33561 14.1841 8.45946C14.3021 8.58331 14.4632 8.65704 14.6341 8.66539C14.3229 11.8216 11.8216 14.3229 8.66537 14.6341C8.65701 14.4632 8.58328 14.3021 8.45943 14.1841C8.33558 14.066 8.17109 14.0001 8 14C7.82891 14.0001 7.66442 14.066 7.54057 14.1841C7.41672 14.3021 7.34299 14.4632 7.33464 14.6341C4.17837 14.3229 1.67714 11.8216 1.36589 8.66539C1.53677 8.65704 1.6979 8.58331 1.81593 8.45946C1.93397 8.33561 1.99987 8.17112 2 8.00003C1.99987 7.82895 1.93397 7.66446 1.81593 7.54061C1.6979 7.41676 1.53677 7.34303 1.36589 7.33467C1.69875 3.95935 4.53519 1.33339 8 1.33339C8.08834 1.33464 8.17605 1.31832 8.25803 1.28538C8.34001 1.25244 8.41462 1.20353 8.47754 1.1415C8.54045 1.07947 8.59041 1.00556 8.6245 0.924053C8.6586 0.842549 8.67616 0.75508 8.67616 0.666731C8.67616 0.578381 8.6586 0.490912 8.6245 0.409408C8.59041 0.327903 8.54045 0.253989 8.47754 0.191959C8.41462 0.12993 8.34001 0.0810228 8.25803 0.0480805C8.17605 0.0151381 8.08834 -0.00118269 8 6.66713e-05ZM7.98958 3.32427C7.813 3.32703 7.64473 3.39974 7.52169 3.52643C7.39866 3.65313 7.33092 3.82346 7.33333 4.00005V7.6667L4.93359 9.46617C4.79201 9.57219 4.69834 9.73011 4.67318 9.90519C4.64803 10.0803 4.69346 10.2582 4.79948 10.3998C4.9055 10.5413 5.06342 10.635 5.2385 10.6602C5.41358 10.6853 5.59149 10.6399 5.73307 10.5339L8.39974 8.53389C8.48265 8.47176 8.54994 8.39116 8.59628 8.2985C8.64261 8.20583 8.66671 8.10364 8.66667 8.00003V4.00005C8.66789 3.91086 8.6512 3.82233 8.61759 3.7397C8.58398 3.65707 8.53413 3.58203 8.47099 3.51902C8.40786 3.456 8.33272 3.4063 8.25003 3.37285C8.16734 3.3394 8.07877 3.32288 7.98958 3.32427Z"
                fill="#5F5F5F"
              />
            </svg>
            <h3 style={{ marginTop: "3px" }}>{myservice.activeDays} Days</h3>
          </div>
        )}
        {/* {(buttonType === "Continue" || buttonType === "Start") && (
          <div className="bar">
            {service.status === "in_progress" && <div className="complate" />}
          </div>
        )} */}
        {isPurchase
          ? privilage.indexOf(access.patientManegment.service.showpurchase) >
              -1 && (
              <div className="fixedButton col-12 d-flex align-items-center">
                <Button
                  type="primary"
                  className="col-9"
                  ghost
                  onClick={() => {
                    if (isPurchase) {
                      path.includes("/in-progress")
                        ? navigator(path + "/" + service.id)
                        : navigator(path + "/in-progress/" + service.id);
                    } else {
                      navigator(path + "/home/" + service.id);
                    }
                    setCurrent(service);
                    setIsPurchase(isPurchase);
                    setIsOpen(true);
                  }}
                >
                  {isPurchase ? "Show" : "More"}
                </Button>
              </div>
            )
          : privilage.indexOf(access.patientManegment.service.showService) >
              -1 && (
              <div className="fixedButton col-12 d-flex align-items-center">
                <Button
                  type="primary"
                  className="col-9"
                  ghost
                  onClick={() => {
                    if (isPurchase) {
                      path.includes("/in-progress")
                        ? navigator(path + "/" + service.id)
                        : navigator(path + "/in-progress/" + service.id);
                    } else {
                      navigator(path + "/home/" + service.id);
                    }
                    setCurrent(service);
                    setIsPurchase(isPurchase);
                    setIsOpen(true);
                  }}
                >
                  {isPurchase ? "Show" : "More"}
                </Button>
              </div>
            )}
      </div>
    </div>
  );
};
export default ServiceCard;
