import React from "react";
import {
  Table,
  Modal,
  Form,
  Input,
  Button,
  Select,
  Space,
  Tag,
  Radio,
} from "antd";
import { useState, useEffect, useRef } from "react";
import { fetchData, putData, postData } from "../../endpoints";
import {
  SearchOutlined,
  PlusOutlined,
  ReloadOutlined,
  FilterOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useMobile } from "../../hooks";
import AddComment from "./comment/addComment";
import access from "../../assets/access";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
function Delay() {
  const [form] = Form.useForm();
  const isMobile = useMobile();
  const [dataSource, setdataSource] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [selectedappointment, setselectedappointment] = useState({});
  const [statusmodal, setstatusmodal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [reply, setReply] = useState(false);
  const searchInput = useRef(null);
  const [SearchUserModal, setSearchUserModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [patients, setPatients] = useState([]);
  const [addnotemodal, setaddnotemodal] = useState(false);
  const [selected, setSelected] = useState({});
  const [meMode, setMeMode] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    const savedState = sessionStorage.getItem("modalState");
    if (savedState) {
      setModalData(JSON.parse(savedState));
      setIsModalVisible(JSON.parse(savedState).showModal);
      sessionStorage.removeItem("modalState");
    }
  }, []);

  const getMeMode = async () => {
    var list_of_purchase = Cookies.get("assinees_purchase")
      .split(" ")
      .map((p) => {
        if (p) return "&purchaseId=" + parseInt(p);
      })
      .toString()
      .replaceAll(",", "");
    if (list_of_purchase == "") {
      setdataSource([]);
      return;
    }
    var res = await fetchData(
      `admin/purchase-step?includes[]=serviceStep&status=missed&perPage=100&includes[]=patient${list_of_purchase}`
    );
    if (res.status == 200) {
      setdataSource(res.data.data);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <a
          href={
            "/patients/" +
            text.patient.id +
            "/services/in-progress/" +
            text.purchaseId
          }
          style={{ color: "black" }}
        >
          {text.purchaseId}
        </a>
      ) : (
        <a
          href={
            "/patients/" +
            text.patient.id +
            "/services/in-progress/" +
            text.purchaseId
          }
          style={{ color: "black" }}
        >
          {text.purchaseId}
        </a>
      ),
  });

  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    setisLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      setdataSource([]);
      await getdelay();
    } else {
      let search = "";
      if (dataIndex == "firstName") search = "fullName";
      if (dataIndex == "purchaseId") search = "purchaseId";

      var res = await fetchData(
        `admin/purchase-step?includes[]=serviceStep&status=missed&includes[]=patient&${search}=` +
          selectedKeys[0]
      );
      if (res.status == 200) {
        res.data.data.map((temp) => {
          temp["name"] = temp.firstName + " " + temp.lastName;
        });

        setdataSource(res.data.data);
      }
    }
    setisLoading(false);
  };
  const handleReset = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    setdataSource([]);
    await getdelay(1, true);
  };
  const filterStepType = async (pagination, filters, sorter, extra) => {
    if (pagination) {
      await getdelay(pagination.current);
    }
    if (filters.type.length > 0) {
      setisLoading(true);
      var p = [];
      for (const s of filters.type) {
        var res = await fetchData(
          `admin/purchase-step?includes[]=serviceStep&status=missed&perPage=15&includes[]=patient&type=` +
            s
        );
        if (res.status == 200) {
          p = res.data.data;
        }
      }
      setdataSource(p);
      setisLoading(false);
    } else {
      await getdelay(pagination.current);
    }
  };
  const columns = [
    {
      title: "Step",
      dataIndex: "",
      key: "",
      // render: (u) => <p>{u.title}</p>,
      render: (u) =>
        u.serviceStep ? (
          <div>
            <a
              href={
                privilage.indexOf(
                  access.patientManegment.service.showpurchase
                ) > -1 &&
                `/patients/${u.patient.id}/services/in-progress/${u.purchaseId}/${u.id}`
              }
              style={{ color: "black" }}
              // target="_blank"
            >
              {u.serviceStep.title}
            </a>
          </div>
        ) : (
          <></>
        ),

      width: "30%",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",

      filterDropdown: () => (
        <div style={{ padding: 8 }}>
          <Radio.Group
            value={typeFilter}
            onChange={async (e) => {
              setisLoading(true);

              setTypeFilter(e.target.value);
              var res = await fetchData(
                `admin/purchase-step?includes[]=serviceStep&type=${e.target.value}&status=missed&perPage=15&includes[]=patient`
              );
              if (res.status == 200) {
                setdataSource(res.data.data);
              }
              setisLoading(false);
            }}
          >
            <Radio value="appointment">Appointment</Radio>
            <Radio value="payment">Payment</Radio>
            <Radio value="form">Form</Radio>
            <Radio value="sign_form">Sign form</Radio>
            <Radio value="document">Document</Radio>
            <Radio value="information">Information</Radio>
          </Radio.Group>
          {typeFilter && (
            <Button
              onClick={() => {
                setTypeFilter(null);
                getdelay(1, true);
              }}
              style={{ marginTop: 8 }}
            >
              Clear
            </Button>
          )}
        </div>
      ),
      filterIcon: <FilterOutlined />,
      render: (word) => <p> {word.charAt(0).toUpperCase() + word.slice(1)}</p>,
    },
    {
      title: (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>Patient</div>{" "}
          <SearchOutlined
            style={{ fontSize: "12px" }}
            onClick={() => {
              setSearchUserModal(true);
            }}
          />
          <ReloadOutlined
            style={{ fontSize: "12px" }}
            onClick={() => {
              getdelay(1, true);
            }}
          />
        </div>
      ),
      dataIndex: "patient",
      key: "patient",
      render: (u) => (
        <a
          href={
            privilage.indexOf(access.patientManegment.showPatient) > -1 &&
            "/patients/" + u.id
          }
          style={{ color: "black" }}
        >
          {u.firstName + " " + u.lastName}
        </a>
      ),
      width: "20%",
    },
    {
      title: "Service",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <a
          href={
            privilage.indexOf(access.patientManegment.service.showPurchase) >
              -1 &&
            "/patients/" +
              u.patient.id +
              "/services/in-progress/" +
              u.purchaseId
          }
          style={{ color: "black" }}
        >
          {u.purchaseId}
        </a>
      ),
      ...getColumnSearchProps("purchaseId"),
      width: "15%",
    },
    privilage.indexOf(access.todoList.addComment) > -1 && {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <Button
          size={isMobile ? "small" : "default"}
          onClick={() => {
            setSelected({
              patientId: u.patientId,
              purchaseId: u.purchaseId,
              purchaseStepId: u.id,
              firstName: u.patient.firstName,
              lastName: u.patient.lastName,
              patient: {
                id: u.patientId,
                firstName: u.patient.firstName,
                lastName: u.patient.lastName,
              },
            });
            setaddnotemodal(true);
          }}
        >
          Add Comment
        </Button>
      ),
    },
    privilage.indexOf(access.todoList.addReply) > -1 && {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <Button
          type="primary"
          size={isMobile ? "small" : "default"}
          onClick={() => {
            setselectedappointment(u);
            setReply(true);
          }}
        >
          Reply
        </Button>
      ),
    },
    privilage.indexOf(access.todoList.changeStatus) > -1 && {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <Button size={isMobile ? "small" : "default"} danger>
          <p
            style={{ whiteSpace: "nowrap" }}
            onClick={() => {
              setselectedappointment(u);
              setstatusmodal(true);
            }}
          >
            Change Status
          </p>
        </Button>
      ),
    },
  ];
  //for search modal
  const okPatientModal = async (id = null) => {
    setisLoading(true);
    var res;
    if (id) {
      res = await fetchData(
        `admin/purchase-step?includes[]=serviceStep&status=missed&perPage=100&includes[]=patient&patientId=${id}`
      );
    } else
      res = await fetchData(
        `admin/purchase-step?includes[]=serviceStep&status=missed&perPage=100&includes[]=patient&patientId=${data[selectedRowKeys].id}`
      );
    setdataSource(res.data.data);
    setSearchUserModal(false);
    setSelectedRowKeys(false);
    setisLoading(false);
  };
  const getColumnSearchPropsmodal = (dataIndex, isRemove = false) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            handleSearchmodal(selectedKeys, confirm, dataIndex);
          }}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchmodal(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleResetmodal(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns2 = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
      onCell: (record) => {
        return {
          onClick: () => {
            okPatientModal(record.id);
          },
        };
      },
      ...getColumnSearchPropsmodal("name"),
      render: (record) => (
        <h2 style={{ cursor: "pointer" }} className="name-table">
          {record}
        </h2>
      ),
    },
    {
      title: "MRN",
      dataIndex: "id",
      key: "id",
      render: (id, record) =>
        record.verifiedAt ? (
          <span>{id}</span>
        ) : (
          <span style={{ color: "rgba(46, 108, 223, 1)", cursor: "pointer" }}>
            Not Yet Verified
          </span>
        ),
      ...getColumnSearchPropsmodal("id"),
    },
  ];

  const handleSearchmodal = async (selectedKeys, confirm, dataIndex) => {
    setisLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length == 0) {
      await getpatientmodal();
    } else {
      let search = "";
      if (dataIndex == "name") search = "fullName";
      else search = "id";
      var res = await fetchData(
        `admin/patient/?verifiedAt=true&deletedAt=false&${search}=` +
          selectedKeys[0]
      );
      if (res.status == 200) {
        setPatients(
          res.data.data.map((temp) => {
            return { ...temp, name: temp.firstName + " " + temp.lastName };
          })
        );
      }
    }
    setisLoading(false);
  };
  const handleResetmodal = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    setdataSource([]);
    await getpatientmodal(1, true);
  };
  const getpatientmodal = async (page = 1, reload = false) => {
    setisLoading(true);
    var temp = patients;
    if (reload) {
      temp = [];
    }
    if (patients.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        `admin/patient/?deletedAt=false&verifiedAt=true&perPage=15&page=` + page
      );
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setPatients(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat(res.data.data)
          );
        } else {
          setPatients(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat([
                ...res.data.data,
                { createdAt: new Date().toString(), serviceTypes: [] },
              ])
          );
        }
      }
    }

    setisLoading(false);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  const data = patients.map((p, i) => {
    return {
      ...p,
      key: i,
      name: p.firstName + " " + p.lastName,
      createdAt: new Date(p.createdAt).toDateString(),
    };
  });

  async function getdelay(page = 1, reload = false) {
    setisLoading(true);
    if (dataSource.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        "admin/purchase-step?includes[]=serviceStep&status=missed&perPage=15&includes[]=patient&page=" +
          page
      );
      var myTemp = dataSource;
      if (reload) {
        myTemp = [];
      }
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          var temp1 = res.data.data.map((temp, i) => {
            return { ...temp, key: i };
          });
          setdataSource(
            myTemp
              .filter((a, i) => {
                return a.id;
              })
              .concat([...temp1])
          );
        } else {
          var temp1 = res.data.data.map((temp, i) => {
            return { ...temp, key: i };
          });
          setdataSource(
            myTemp
              .filter((a, i) => {
                return a.id;
              })
              .concat([...temp1, {}])
          );
        }
      } else {
        Modal.error({
          title: res,
        });
      }
      //
    }
    setisLoading(false);
  }
  useEffect(() => {
    getdelay();
    getpatientmodal();
  }, []);

  return (
    <>
      <div
        style={{
          minHeight: "150vh",
          minWidth: "100%",
          backgroundColor: "white",
        }}
      >
        <div
          style={
            isMobile
              ? {
                  padding: "0px 20px",
                  // textAlign: "center",
                  margin: "auto",
                  minHeight: "50%",
                }
              : {
                  padding: "0px 70px",
                  textAlign: "center",
                  margin: "auto",
                  minHeight: "50%",
                }
          }
        >
          <div
            style={
              isMobile
                ? {
                    padding: "40px 0px 20px 0px",
                    fontSize: "18px",
                  }
                : {
                    display: "flex",
                    justifyContent: "start",
                    padding: "30px 0px",
                    fontSize: "24px",
                  }
            }
          >
            <strong>Delay in action</strong>
            
            <Button
              style={{ position: "absolute", right: "5rem" }}
              onClick={async () => {
                setisLoading(true);
                setMeMode(!meMode);
                if (!meMode) {
                  await getMeMode();
                } else {
                  await getdelay(1, true);
                }
                setisLoading(false);
              }}
              type={meMode ? "primary" : "default"}
              loading={isLoading}
              shape="round"
            >
              Assigned to me
            </Button>
          </div>

          <div
            style={{
              fontWeight: "400",
              fontSize: "16px",
              // marginTop: "36px",
              textAlign: "start",
              marginBottom: "40px",
            }}
          >
            Check service steps where users missed deadlines.{" "}
          </div>
          <div style={isMobile ? { minWidth: "80vw" } : {}}>
            <Table
              scroll={{ x: "auto" }}
              loading={isLoading}
              columns={columns}
              dataSource={dataSource}
              onChange={filterStepType}
              pagination={{
                onChange: async (page) => {
                  await getdelay(page);
                },
                pageSize: 15,
              }}
            />
          </div>
        </div>
      </div>
      <Modal
        title=""
        open={statusmodal}
        okText="Verify"
        onCancel={() => setstatusmodal(false)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <strong
          style={{
            textAlign: "center",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            fontSize: "24px",
          }}
        >
          Change status
        </strong>

        <Form
          form={form}
          layout="vertical"
          onFinish={async (values) => {
            setisLoading(true);
            var res = await putData(
              `admin/service-purchase/${selectedappointment.purchaseId}/step/${selectedappointment.id}`,
              values
            );
            if (res.status == 200) {
              setstatusmodal(false);
              Modal.success({ title: "Status changed successfully." });
              form.resetFields();
              await getdelay(1, true);
            } else {
              Modal.error({ title: res });
            }
            setisLoading(false);
          }}
        >
          <Form.Item label="New Status" name="status">
            <Select placeholder="Enter new Status">
              <Select.Option value="no_show">No show</Select.Option>
              <Select.Option value="done">Done</Select.Option>
              <Select.Option value="active">Active</Select.Option>
              <Select.Option value="is_scheduled">Is scheduled</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
            <Button
              type="default"
              onClick={() => {
                setstatusmodal(false);
                setselectedappointment({});
              }}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title=""
        open={reply}
        // okText="Send"
        footer={null}
        onCancel={() => {
          setReply(false);
          form.resetFields();
        }}
      >
        <strong
          style={{
            textAlign: "center",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            fontSize: "24px",
          }}
        >
          Send reply
        </strong>

        <Form
          form={form}
          // layout="vertical"
          onFinish={async (values) => {
            setisLoading(true);
            var temp = {
              purchaseId: selectedappointment.purchaseId,
              purchaseStepId: selectedappointment.id,
              subject: values.subject ?? "",
              message: values.message,
            };
            var res = await postData(
              `admin/service-purchase/${selectedappointment.purchaseId}/step/${selectedappointment.id}/reply`,
              temp
            );
            if (res.status == 201) {
              setReply(false);
              Modal.success({ title: "Reply sent successfully." });
              form.resetFields();
              await getdelay(1, true);
            } else {
              Modal.error({ title: res });
            }
            setisLoading(false);
          }}
        >
          <Form.Item label="Subject" name="subject">
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            label="Message"
            name="message"
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
            <Button
              type="default"
              onClick={() => {
                setReply(false);
                setselectedappointment({});
                form.resetFields();
              }}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={SearchUserModal}
        title="Search Patient"
        width={1000}
        onOk={async () => {
          await okPatientModal();
        }}
        onCancel={() => {
          setSearchUserModal(false);
          setSelectedRowKeys(false);
        }}
        style={isMobile ? {} : { maxHeight: "80vh" }}
      >
        <div
          style={
            isMobile
              ? { minWidth: "90%" }
              : { maxHeight: "75vh", overflowY: "scroll" }
          }
        >
          <Table
            scroll={{ x: "auto" }}
            loading={isLoading}
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            columns={columns2}
            dataSource={data}
            size="small"
            style={isMobile ? { margin: "0px" } : { margin: "40px" }}
            pagination={{
              onChange: async (page) => {
                await getpatientmodal(page);
              },
              // total: 50,
              pageSize: 15,
            }}
          />
        </div>
      </Modal>
      <Modal
        title=""
        open={addnotemodal}
        top={"0"}
        width={isMobile ? "90vw" : "65vw"}
        footer={null}
        onCancel={() => setaddnotemodal(false)}
        destroyOnClose
        closable={false}
      >
        <AddComment
          isOpen={addnotemodal}
          setIsOpen={setaddnotemodal}
          getComments={() => {}}
          patients={[
            {
              id: selected.patientId,
              firstName: selected.firstName,
              lastName: selected.lastName,
            },
          ]}
          purchase={null}
          defultComment={selected}
          setDefult={setSelected}
        />
      </Modal>
      <Modal
        visible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false);
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        closeIcon={null}
      >
        <div className="d-flex flex-row align-items-center gap-2">
          <CloseCircleFilled style={{ color: "#ff4d4f" }} />
          <p style={{ fontWeight: "700", margin: "0" }}>
            {modalData?.message || "Something went wrong!"}
          </p>
        </div>
      </Modal>
    </>
  );
}

export default Delay;
