import React from "react";
import { useConfig } from "../../hooks/useConfig";
import { Table, Modal, Button, Form, Input, Space, Radio } from "antd";
import { useMobile } from "../../hooks";
import { useState, useEffect, useRef } from "react";
import "./ReplyStyle.scss";

import { fetchData, putData } from "../../endpoints";
import { CloseCircleFilled, ExclamationCircleFilled, FilterOutlined } from "@ant-design/icons";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import AddComment from "./comment/addComment";
import access from "../../assets/access";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 10, span: 12 },
};

function Payments() {
  const [form] = Form.useForm();
  const isMobile = useMobile();
  const [approvemodal, setapprovemodal] = useState(false);
  const [rejectmodal, setrejectmodal] = useState(false);
  const [addnotemodal, setaddnotemodal] = useState(false);
  const [dataSource, setdataSource] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [selectedservice, setselectedservice] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [SearchUserModal, setSearchUserModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [patients, setPatients] = useState([]);
  const [selected, setSelected] = useState({});
  const searchInput = useRef(null);
  const { getUserConfig } = useConfig();
  const [config, setConfig] = useState([]);
  const [meMode, setMeMode] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    const savedState = sessionStorage.getItem("modalState");
    if (savedState) {
      setModalData(JSON.parse(savedState));
      setIsModalVisible(JSON.parse(savedState).showModal);
      sessionStorage.removeItem("modalState");
    }
  }, []);
  const approve = async (u=null) => {
    setisLoading(true);
    if(u){
      var res = await putData(
        `admin/service-purchase/${u.purchaseId}/step/${u.id}/approve`
      );
    }else{
      var res = await putData(
        `admin/service-purchase/${selectedservice.purchaseId}/step/${selectedservice.id}/approve`
      );
    }
    
    if (res.status == 200) {
      setapprovemodal(false);
      await getneedapprove(1, true);
      setisLoading(false);
      Modal.success({
        title: "this step approved successfully",
      });
    } else {
      setisLoading(false);
      Modal.error({ title: res });
    }
  };
  const showApproveConfirm = async (u) => {
    Modal.confirm({
      title: "Are you sure approve this payment?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        approve(u);
      },
      onCancel() {},
    });
  };
  const getMeMode = async () => {
    var list_of_purchase = Cookies.get("assinees_purchase")
      .split(" ")
      .map((p) => {
        if (p) return "&purchaseId[]=" + parseInt(p);
      })
      .toString()
      .replaceAll(",", "");
      if (list_of_purchase == "") {
        setdataSource([]);
          return;
        }
    var res = await fetchData(
      `admin/purchase-step?sortValue=-1&sortKey=updatedAt&includes[]=serviceStep.service&type=payment&status=need_admin_approve&includes[]=patient${list_of_purchase}`
    );

    if (res.status == 200) {
      setdataSource(res.data.data);
    }
  };

  const getConfig = async () => {
    getUserConfig();
    var res = JSON.parse(localStorage.getItem("config"));
    setConfig(res.data.data);
  };

  const okPatientModal = async (id = null) => {
    var res;
    if (id) {
      res = await fetchData(
        `admin/purchase-step?sortValue=-1&sortKey=updatedAt&includes[]=serviceStep&type=payment&status=need_admin_approve&includes[]=patient&patientId=${id}`
      );
    } else
      res = await fetchData(
        `admin/purchase-step?sortValue=-1&sortKey=updatedAt&includes[]=serviceStep&type=payment&status=need_admin_approve&includes[]=patient&patientId=${data[selectedRowKeys].id}`
      );
    setdataSource(res.data.data);

    setSearchUserModal(false);
    setSelectedRowKeys(false);
  };

  const getColumnSearchPropsmodal = (dataIndex, isRemove = false) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            handleSearchmodal(selectedKeys, confirm, dataIndex);
          }}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchmodal(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleResetmodal(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns2 = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
      onCell: (record) => {
        return {
          onClick: () => {
            okPatientModal(record.id);
          },
        };
      },
      ...getColumnSearchPropsmodal("name"),
      render: (record) => (
        <h2 style={{ cursor: "pointer" }} className="name-table">
          {record}
        </h2>
      ),
    },
    {
      title: "MRN",
      dataIndex: "id",
      key: "id",
      render: (id, record) =>
        record.verifiedAt ? (
          <span>{id}</span>
        ) : (
          <span style={{ color: "rgba(46, 108, 223, 1)", cursor: "pointer" }}>
            Not Yet Verified
          </span>
        ),
      ...getColumnSearchPropsmodal("id"),
    },
  ];

  const handleSearchmodal = async (selectedKeys, confirm, dataIndex) => {
    setisLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length == 0) {
      await getpatientmodal();
    } else {
      let search = "";
      if (dataIndex == "name") search = "fullName";
      else search = "id";
      var res = await fetchData(
        `admin/patient/?verifiedAt=true&deletedAt=false&${search}=` +
          selectedKeys[0]
      );
      if (res.status == 200) {
        setPatients(
          res.data.data.map((temp) => {
            return { ...temp, name: temp.firstName + " " + temp.lastName };
          })
        );
      }
    }
    setisLoading(false);
  };
  const handleResetmodal = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    // setdataSource([]);
    await getneedapprove(1, true);
    await getpatientmodal(1, true);
  };
  const getpatientmodal = async (page = 1, reload = false) => {
    setisLoading(true);
    var temp = patients;
    if (reload) {
      temp = [];
    }
    if (patients.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        `admin/patient/?deletedAt=false&verifiedAt=true&perPage=15&page=` + page
      );
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setPatients(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat(res.data.data)
          );
        } else {
          setPatients(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat([
                ...res.data.data,
                { createdAt: new Date().toString(), serviceTypes: [] },
              ])
          );
        }
      }
    }

    setisLoading(false);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  const data = patients.map((p, i) => {
    return {
      ...p,
      key: i,
      name: p.firstName + " " + p.lastName,
      createdAt: new Date(p.createdAt).toDateString(),
    };
  });
  const columns_Need = [
    {
      title: "Step",
      dataIndex: "",
      key: "",

      render: (u) =>
        u.serviceStep ? (
          <div>
            <a
              href={
                privilage.indexOf(
                  access.patientManegment.service.showpurchase
                ) > -1 &&
                `/patients/${u.patient.id}/services/in-progress/${u.purchaseId}/${u.id}`
              }
              style={{ color: "black" }}
              // target="_blank"
            >
              {u.serviceStep.title}
            </a>
          </div>
        ) : (
          <></>
        ),
    },
    {
      title: (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>Patient</div>{" "}
          <SearchOutlined
            style={{ fontSize: "12px" }}
            onClick={() => {
              setSearchUserModal(true);
            }}
          />
          <ReloadOutlined
            style={{ fontSize: "12px" }}
            onClick={() => {
             handleResetmodal(()=>{})
            }}
          />
        </div>
      ),
      dataIndex: "patient",
      key: "patient",
      render: (u) =>
        u ? (
          <a
            style={{ color: "black" }}
            href={
              privilage.indexOf(access.patientManegment.showPatient) > -1 &&
              "/patients/" + u.id
            }
          >
            {u.firstName + "  " + u.lastName}
          </a>
        ) : (
          <></>
        ),
    },
    {
      title: "Patient ID",
      dataIndex: "patientId",
      key: "patientId",
    },

    {
      title: "Price",
      dataIndex: "serviceStep",
      key: "price",
      width: "10%",

      render: (u) => u && <p>{u.price} $</p>,
    },
    {
      title: "Service",
      dataIndex: "",
      key: "",
      render: (u) =>
        u.serviceStep && u.serviceStep.service ? (
          <div>
            <a
              href={
                privilage.indexOf(
                  access.patientManegment.service.showpurchase
                ) > -1 &&
                `/patients/${u.patient.id}/services/in-progress/${u.purchaseId}`
              }
              // target="_blank"
              style={{ color: "black" }}
            >
              {u.serviceStep.service.title}
            </a>
          </div>
        ) : (
          <></>
        ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <div className="d-flex flex-row gap-2">
          {privilage.indexOf(access.todoList.addComment) > -1 && (
            <Button
              onClick={() => {
                setSelected({
                  patientId: u.patientId,
                  purchaseStepId: u.id,
                  purchaseId: u.purchaseId,
                  firstName: u.patient.firstName,
                  lastName: u.patient.lastName,
                  patient: {
                    id: u.patientId,
                    firstName: u.patient.firstName,
                    lastName: u.patient.lastName,
                  },
                });
                setaddnotemodal(true);
              }}
              size={isMobile ? "small" : "default"}
            >
              <a
                style={{
                  color: "black",
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                }}
              >
                Add Comment
              </a>
            </Button>
          )}
          {privilage.indexOf(access.todoList.approve) > -1 && (
            <Button
              style={{ backgroundColor: "#389e0d" }}
              size={isMobile ? "small" : "default"}
              onClick={async() => {
               await setselectedservice(u);
                showApproveConfirm(u);
              }}
            >
              <a style={{ color: "#ffff" }}>Approve</a>
            </Button>
          )}
          {privilage.indexOf(access.todoList.reject) > -1 && (
            <Button danger size={isMobile ? "small" : "default"}>
              <a
                onClick={() => {
                  setselectedservice(u);
                  setrejectmodal(true);
                }}
                // style={{ color: "#ffff" }}
              >
                Decline
              </a>
            </Button>
          )}
        </div>
      ),
    },
  ];

  async function getneedapprove(page = 1, relod = false) {
    setisLoading(true);

    if (dataSource.length < (page - 1) * 15 + 2 || relod) {
      var res = await fetchData(
        "admin/purchase-step?sortValue=-1&sortKey=updatedAt&includes[]=serviceStep.service&type=payment&status=need_admin_approve&perPage=15&includes[]=patient&page=" +
          page
      );

      var temp = dataSource;
      if (relod) {
        temp = [];
      }
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setdataSource(
            temp
              .filter((a, i) => {
                return a.id;
              })
              .concat([...res.data.data])
          );
        } else {
          setdataSource(
            temp
              .filter((a, i) => {
                return a.id;
              })
              .concat([...res.data.data, {}])
          );
        }
      } else {
        Modal.error({
          title: res,
        });
      }
    }

    setisLoading(false);
  }

  useEffect(() => {
    getConfig();
    getneedapprove();

    if (privilage.indexOf(access.todoList.indexPatient) > -1) {
      getpatientmodal();
    }
  }, []);

  return (
    <>
      <div
        style={{
          minHeight: "150vh",
          minWidth: "100%",
          backgroundColor: "white",
        }}
      >
        <div
          style={
            isMobile
              ? {
                  padding: "0px 20px",
                  // textAlign: "center",
                  margin: "auto",
                  minHeight: "50%",
                }
              : {
                  padding: "0px 70px",
                  textAlign: "center",
                  margin: "auto",
                  minHeight: "50%",
                }
          }
        >
          <div
            style={
              isMobile
                ? {
                    padding: "40px 0px 20px 0px",
                    fontSize: "18px",
                  }
                : {
                    display: "flex",
                    justifyContent: "start",
                    padding: "40px 0px",
                    fontSize: "24px",
                  }
            }
          >
             <div style={{ textAlign:'left',}} className="d-flex flex-column">
            <strong>Payment’s Steps</strong>
            <div
              style={{ fontWeight: "400", fontSize: "16px", marginTop: "20px" }}
            >
             View and manage patient payments for different service steps and treatment plans.</div>
             </div>
            <Button
              style={{ position: "absolute", right: "5rem" }}
              onClick={async () => {
                setisLoading(true);
                setMeMode(!meMode);
                if (!meMode) {
                  await getMeMode();
                } else {
                  await getneedapprove(1, true);
                }
                setisLoading(false);
              }}
              type={meMode ? "primary" : "default"}
              loading={isLoading}
              shape="round"
            >
              Assigned to me
            </Button>
          </div>
          <div style={isMobile ? { minWidth: "80vw" } : {}}>
            <Table
              scroll={{ x: "auto" }}
              columns={columns_Need}
              dataSource={dataSource}
              loading={isLoading}
              pagination={{
                onChange: async (page) => {
                  await getneedapprove(page);
                },

                pageSize: 15,
              }}
            />
          </div>
        </div>
      </div>
      <Modal
        loading={isLoading}
        title="Approve"
        open={approvemodal}
        okText="Approve"
        onCancel={() => setapprovemodal(false)}
        confirmLoading={isLoading}
        onOk={async () => {
          setisLoading(true);
          var res = await putData(
            `admin/service-purchase/${selectedservice.purchaseId}/step/${selectedservice.id}/approve`
          );
          if (res.status == 200) {
            setapprovemodal(false);
            await getneedapprove(1, true);
            setisLoading(false);
            Modal.success({
              title: "this step approved successfully",
            });
          } else {
            setisLoading(false);
            Modal.error({ title: res });
          }
        }}
      >
        <p>Are you sure to Approve this step?</p>
      </Modal>
      <Modal
        title=""
        open={rejectmodal}
        footer={null}
        onCancel={() => setrejectmodal(false)}
        confirmLoading={isLoading}
      >
        <div style={{ textAlign: "center", fontSize: "36px" }}>
          <strong>Decline</strong>
        </div>
        <div style={{ textAlign: "center" }}>
          Please specify the reason for rejecting this step.
        </div>
        <hr />

        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={async (values) => {
            setisLoading(true);
            let temp = {
              subject: values.subject ?? "",
              message: values.message,
            };
            var res = await putData(
              `admin/service-purchase/${selectedservice.purchaseId}/step/${selectedservice.id}/decline`,
              temp
            );

            if (res.status == 200) {
              setrejectmodal(false);
              await getneedapprove(1, true);
              Modal.success({ title: "The step has been declined successfully" });
            } else {
              Modal.error({ title: res });
            }
            form.resetFields();
            setisLoading(false);
          }}
          style={{ maxWidth: 600 }}
        >
          <Form.Item
            name="subject"
            label="Subject"
            // rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="message"
            label="Message"
            rules={[{ required: true }]}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title=""
        open={addnotemodal}
        top={"0"}
        width={isMobile ? "90vw" : "65vw"}
        footer={null}
        onCancel={() => setaddnotemodal(false)}
        closeIcon={null}
      >
        <AddComment
          isOpen={addnotemodal}
          setIsOpen={setaddnotemodal}
          getComments={() => {}}
          patients={[
            {
              id: selected.patientId,
              firstName: selected.firstName,
              lastName: selected.lastName,
            },
          ]}
          purchase={null}
          defultComment={selected}
          selecting={true}
          setDefult={setSelected}
        />
      </Modal>

      <Modal
        open={SearchUserModal}
        title="Search Patient"
        width={1000}
        onOk={async () => {
          await okPatientModal();
        }}
        okText={"Ok"}
        onCancel={() => {
          setSearchUserModal(false);
          setSelectedRowKeys(false);
        }}
        style={isMobile ? {} : { maxHeight: "80vh" }}
      >
        <div
          style={
            isMobile
              ? { minWidth: "90%" }
              : { maxHeight: "75vh", overflowY: "scroll" }
          }
        >
          <Table
            scroll={{ x: "auto" }}
            loading={isLoading}
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            columns={columns2}
            dataSource={data}
            size="small"
            style={isMobile ? { margin: "0px" } : { margin: "40px" }}
            pagination={{
              onChange: async (page) => {
                await getpatientmodal(page);
              },
              // total: 50,
              pageSize: 15,
            }}
          />
        </div>
      </Modal>
      <Modal
        visible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false);
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        closeIcon={null}
      >
        <div className="d-flex flex-row align-items-center gap-2">
        <CloseCircleFilled style={{color:'#ff4d4f'}}/>
        <p style={{ fontWeight: "700" , margin:'0' }}>
          {modalData?.message || "Something went wrong!"}
        </p>
        </div>
      </Modal>
    </>
  );
}

export default Payments;
