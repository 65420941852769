import React, { useState, useEffect } from "react";
import "./styles.scss";
import { useAssets } from "../../hooks/index";
import { useMobile } from "../../hooks/index";
import {
  Button,
  Form,
  Input,
  Select,
  Tag,
  Mentions,
  Modal,
  Upload,
} from "antd";
import ReactQuill from "react-quill";
import {
  DownOutlined,
  PlusCircleFilled,
  MinusCircleFilled,
  InboxOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { fetchData, postData, postFormData } from "../../endpoints";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};

var modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

var formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];

function SendEmailPage() {
  const isMobile = useMobile();
  const { getFile } = useAssets();
  const [form] = Form.useForm();
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [cc, setCc] = useState(null);
  const [bcc, setBcc] = useState(null);
  const [CCs, setCCs] = useState([]);
  const [BCCs, setBCCs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [emailInfo, setEmailInfo] = useState({});
  const [message, setMessage] = useState("");
  const location = useLocation();
  const [Users, setUsers] = useState([]);
  const [isLoadingMention, setIsLoadingMention] = useState(false);
  const [receivers, setReceivers] = useState([]);
  const [to, setTo] = useState(null);
  const [IsShowModalAttachment, setIsShowModalAttachment] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const [fileList, setFileList] = useState([]);

  const showModal = () => setIsShowModalAttachment(true);
  const hideModal = () => setIsShowModalAttachment(false);

  const { Dragger } = Upload;
  const props = {
    name: "file",
    multiple: true,

    beforeUpload: async (file) => {
      setIsLoading(true);

      const data = new FormData();
      data.append("file", file);

      var res = await postFormData("admin/template-attachment", data);

      if (res.status == 201) {
        setAttachments((prev) => [...prev, { ...file, id: res.data.data.id }]);
      }

      setIsLoading(false);
      return false;
    },

    // onDrop: async (e) => {
    //   setIsLoading(true);

    //   const data = new FormData();
    //   data.append("file", e.dataTransfer.files[0]);

    //   var res = await postFormData("admin/template-attachment", data);

    //   if (res.status == 201) {
    //     setAttachments((prev) => [
    //       ...prev,
    //       { ...e.dataTransfer.files[0], id: res.data.data.id },
    //     ]);
    //   }

    //   setIsLoading(false);
    //   return false;
    // },
    onRemove(file) {
      // console.log(file);
      setAttachments((prevList) =>
        prevList.filter((item) => item.uid !== file.uid)
      );

      console.log(attachments);
    },
  };

  const queryParams = new URLSearchParams(location.search);
  const Send = async () => {
    setIsLoading(true);
    var opt = {};
    if (CCs.length > 0) {
      opt = {
        ...opt,
        cc: [
          ...CCs.map((c) => {
            return c.email;
          }),
        ],
      };
    }
    if (BCCs.length > 0) {
      opt = {
        ...opt,
        bcc: [
          ...BCCs.map((c) => {
            return c.email;
          }),
        ],
      };
    }
    const tempResivers = receivers.map((r) => {
      return r.id;
    });

    var attachment = [];

    attachments.map((attach) => {
      if (attach.id) attachment.push(attach.id);
    });
    var res = await postData("admin/template/send", {
      templateId: emailInfo.id,
      sender: emailInfo.senders,
      subject: emailInfo.subject,
      message: message,
      receivers: tempResivers,
      options: opt,
      attachments: attachment,
    });
    if (res.status == 201) {
      Modal.success({
        title: res.data.message,
        onOk: () => {
          window.location.reload();
        },
      });
    } else {
      Modal.error({ title: res });
    }
    setIsLoading(false);
  };
  const templateId = queryParams.get("templateId");
  const template = queryParams.get("template");
  const getUsers = async (data = null) => {
    setIsLoadingMention(true);
    if (data) {
      var res = await fetchData(
        `admin/user/?disabledAt=false&deletedAt=false&perPage=15&page=1&email=${data}`
      );
    } else {
      var res = await fetchData(
        `admin/user/?disabledAt=false&deletedAt=false&perPage=15&page=1`
      );
    }
    if (res.status == 200) {
      setUsers(res.data.data);
    } else {
      Modal.error({ title: res });
    }
    setIsLoadingMention(false);
  };
  async function fetchEmailsTemplates() {
    setIsLoading(true);
    var res = await fetchData("admin/template");

    if (res.status == 200) {
      setEmailTemplates(res.data.data);
    }
    setIsLoading(false);
  }

  async function fetchEmailsTemplate(id) {
    setIsLoading(true);

    var res = await fetchData(`admin/template/${id}`);
    if (res.status == 200) {
      setEmailInfo(res.data.data);
      setMessage(res.data.data.message);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchEmailsTemplates();

    if (templateId) {
      fetchEmailsTemplate(templateId);
    }
    getUsers();
  }, []);

  return (
    <>
      <div
        style={{
          width: "100vw",
          minHeight: "100vh",
          backgroundColor: "white",
          padding: "3rem",
        }}
      >
        <img
          src={getFile("Logo")}
          style={{
            maxWidth: " 30vw",
          }}
        />
        <hr />

        <div
          style={
            isMobile
              ? { padding: "1rem 1rem", width: "100%", margin: "auto" }
              : { padding: "1rem 1rem", width: "75%", margin: "auto" }
          }
        >
          <Form {...layout} form={form} name="control-hooks">
            <Form.Item
              name="to"
              label="To"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <div className="col-12 d-flex flex-row gap-2">
                <Mentions
                  placeholder="input @ to mention people"
                  loading={isLoadingMention}
                  onSelect={(value) => {
                    setTo(value);
                  }}
                  options={Users.filter((u) => {
                    return u.email;
                  }).map((u) => {
                    return { value: u.email, label: u.email };
                  })}
                  onSearch={(e) => {
                    getUsers(e);
                  }}
                />
                {to && to.value && (
                  <PlusCircleFilled
                    style={{ marginLeft: "-30px", zIndex: "5" }}
                    onClick={() => {
                      if (to) {
                        var temp = Users.filter((u) => {
                          return u.email == to.value;
                        });
                        if (temp.length > 0) {
                          setReceivers([
                            ...receivers,
                            { email: to.label, id: temp[0].id },
                          ]);
                        }
                        setTo(null);
                      }
                    }}
                  />
                )}
                <div style={{ display: "flex" }}>
                  {!showCc && (
                    <div
                      style={{ padding: "0px 16px", cursor: "pointer" }}
                      onClick={() => setShowCc(!showCc)}
                    >
                      Cc
                    </div>
                  )}
                  {!showBcc && (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowBcc(!showBcc)}
                    >
                      BCc
                    </div>
                  )}
                </div>
              </div>
            </Form.Item>
            <div
              style={{
                marginTop: "-15px",
                marginLeft: "25%",
                flexWrap: "wrap",
              }}
              className="d-flex flex-row gap-1 col-10 align-items-center mb-4"
            >
              {receivers.map((a) => {
                return (
                  <div
                    key={a.id}
                    style={{ width: "20%" }}
                    className={isMobile && "col-6"}
                  >
                    <Tag className="d-flex flex-row gap-1">
                      <MinusCircleFilled
                        onClick={async () => {
                          setReceivers(
                            receivers.filter((r) => {
                              return r.id != a.id;
                            })
                          );
                        }}
                      />
                      {a.email.length < 18
                        ? a.email
                        : a.email.substr(0, 15) + "..."}
                    </Tag>
                  </div>
                );
              })}
            </div>

            {showCc && (
              <>
                {" "}
                <Form.Item name="cc" label="CC">
                  <div className="d-flex flex-row gap-2">
                    <Mentions
                      placeholder="input @ to mention people"
                      loading={isLoadingMention}
                      // value={cc[0]}
                      onSelect={(value) => {
                        setCc(value);
                      }}
                      options={Users.filter((u) => {
                        return u.email;
                      }).map((u) => {
                        return { value: u.email, label: u.email };
                      })}
                      onSearch={(e) => {
                        getUsers(e);
                      }}
                    />
                    {cc && cc.value && (
                      <PlusCircleFilled
                        style={{ marginLeft: "-30px", zIndex: "5" }}
                        onClick={() => {
                          if (cc) {
                            var temp = Users.filter((u) => {
                              return u.email == cc.value;
                            });
                            if (temp.length > 0) {
                              setCCs([
                                ...CCs,
                                { email: cc.label, id: temp[0].id },
                              ]);
                              setCc(null);
                            }
                          }
                        }}
                      />
                    )}
                  </div>
                </Form.Item>
                <div
                  style={{
                    marginTop: "-15px",
                    marginLeft: "25%",
                    flexWrap: "wrap",
                  }}
                  className="d-flex flex-row gap-1 col-10 align-items-center mb-4"
                >
                  {CCs.map((a) => {
                    return (
                      <div
                        key={a.id}
                        style={{ width: "20%" }}
                        className={isMobile && "col-6"}
                      >
                        <Tag className="d-flex flex-row gap-1">
                          <MinusCircleFilled
                            onClick={async () => {
                              setCCs(
                                CCs.filter((r) => {
                                  return r.id != a.id;
                                })
                              );
                            }}
                          />
                          {a.email.length < 18
                            ? a.email
                            : a.email.substr(0, 15) + "..."}
                        </Tag>
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            {showBcc && (
              <>
                <Form.Item name="bcc" label="Bcc">
                  <div className="d-flex flex-row gap-2">
                    <Mentions
                      placeholder="input @ to mention people"
                      loading={isLoadingMention}
                      onSelect={(value) => {
                        setBcc(value);
                      }}
                      onSearch={(e) => {
                        getUsers(e);
                      }}
                      options={Users.filter((u) => {
                        return u.email;
                      }).map((u) => {
                        return { value: u.email, label: u.email };
                      })}
                    />
                    {bcc && bcc.value && (
                      <PlusCircleFilled
                        style={{ marginLeft: "-30px", zIndex: "5" }}
                        onClick={() => {
                          if (bcc) {
                            var temp = Users.filter((u) => {
                              return u.email == bcc.value;
                            });
                            if (temp.length > 0)
                              setBCCs([
                                ...BCCs,
                                { email: bcc.label, id: temp[0].id },
                              ]);
                            setBcc(null);
                          }
                        }}
                      />
                    )}
                  </div>
                </Form.Item>
                <div
                  style={{
                    marginTop: "-15px",
                    marginLeft: "25%",
                    flexWrap: "wrap",
                  }}
                  className="d-flex flex-row gap-1 col-10 align-items-center mb-4"
                >
                  {BCCs.map((a) => {
                    return (
                      <div
                        key={a.id}
                        style={{ width: "20%" }}
                        className={isMobile && "col-6"}
                      >
                        <Tag className="d-flex flex-row gap-1">
                          <MinusCircleFilled
                            onClick={async () => {
                              setBCCs(
                                BCCs.filter((r) => {
                                  return r.id != a.id;
                                })
                              );
                            }}
                          />
                          {a.email.length < 18
                            ? a.email
                            : a.email.substr(0, 15) + "..."}
                        </Tag>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            <Form.Item
              name="Email’s Template"
              label="Email’s Template"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Choose One"
                onChange={(e) => fetchEmailsTemplate(e)}
                defaultValue={template ?? template}
              >
                {emailTemplates.map((temp) => {
                  return <Option value={temp.id}>{temp.subject}</Option>;
                })}
              </Select>
            </Form.Item>
          </Form>

          <div
            style={{
              boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 8px",
              padding: "40px 40px",
            }}
          >
            <Input
              className="subject-input"
              placeholder="Subject"
              value={emailInfo.subject}
              onChange={(e) => {
                setEmailInfo({ ...emailInfo, subject: e.target.value });
              }}
            />
            <div style={{ position: "relative" }}>
              <ReactQuill
                value={message}
                formats={formats}
                modules={modules}
                onChange={(e) => {
                  setMessage(e);
                }}
              />
              <div
                style={{
                  position: "absolute",
                  right: "0",
                  top: "0",
                  padding: "8px",
                }}
              >
                <Button
                  size="small"
                  type="contained"
                  onClick={() => {
                    setIsShowModalAttachment(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-paperclip"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
                  </svg>
                </Button>
              </div>
            </div>

            <Button
              type="primary"
              style={{ marginTop: "20px" }}
              loading={isLoading}
              onClick={Send}
            >
              Send
            </Button>
          </div>
        </div>
      </div>

      <Modal
        title="Upload Files"
        visible={IsShowModalAttachment}
        onCancel={hideModal}
        footer={[
          <Button key="cancel" onClick={hideModal} loading={isLoading}>
            Confirm
          </Button>,
        ]}
      >
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click to upload</p>
          {/* <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibited from
            uploading company data or other banned files.
          </p> */}
        </Dragger>
      </Modal>
    </>
  );
}

export default SendEmailPage;
