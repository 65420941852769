import "./styles.scss";

import {
  Button,
  Input,
  Modal,
  Form,
  InputNumber,
  Select,
  Switch,
  Spin,
  Tag,
  message,
} from "antd";

import { postData, deleteData, putData, fetchData } from "../../endpoints";
import { PlusOutlined } from "@ant-design/icons";
import FormItem from "antd/es/form/FormItem";
import { useState } from "react";
import "react-quill/dist/quill.snow.css";
import access from "../../assets/access";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
const Step = ({
  newService,
  setNewService,
  setStep,
  setAddCond,
  i,
  event,
  //   postStep,
  //   editStep,
  deleteStep,
  //   isLoading,
  loading2,
  setLoading2,
  isMobile,
  setLoading3,
  loading3,
  step,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState(step && step.metadata ? step.metadata : []);
  const [deletedTags, setDeletedTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const postStep = async (step, i) => {
    if (!step.title) {
      Modal.error({
        title: "Title is required. Please enter a descriptive title.",
      });
      return;
    }
    if (!step.type) {
      Modal.error({
        title:
          "Type is required. Please select a valid option from the dropdown.",
      });
      return;
    }
    if (!step.description) {
      Modal.error({
        title: "Details are required. Please provide more information.",
      });
      return;
    }

    setIsLoading(true);
    var temp2 = step;
    var temp1 = {
      ...temp2,
      serviceId: newService.id,
      startDays: temp2.startDays ?? 0,
      expireDays: temp2.expireDays ?? newService.activeDays,
    };
    if (
      step.type == "information" ||
      step.type == "payment" ||
      step.type == "document"
    ) {
      temp1 = { ...temp1, modelId: null };
    }

    var res = await postData("admin/service-step", temp1);
    if (res.status == 201) {
      var temp = newService.steps;
      temp[i] = { ...res.data.data, isEdit: false, blocks: step.blocks };
      setNewService({ ...newService, steps: temp });

      message.success(res.data.message, 2);

      setIsLoading(false);

      tags.map(async (tag) => {
        if (!tag.id) {
          var res = await postData("admin/service-metadata", {
            ...tag,
            modelId: newService.steps[i].id,
          });
        }
      });
    } else {
      Modal.error({ title: res });
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const editStep = async (step, i) => {
    if (!step.title) {
      Modal.error({
        title: "Title is required. Please enter a descriptive title.",
      });
      return;
    }
    if (!step.type) {
      Modal.error({
        title:
          "Type is required. Please select a valid option from the dropdown.",
      });
      return;
    }
    if (!step.description) {
      Modal.error({
        title: "Details are required. Please provide more information.",
      });
      return;
    }

    setIsLoading(true);
    var temp1 = {
      ...step,
      startDays: step.startDays && step.startDays >= 0 ? step.startDays : 0,
      expireDays: step.expireDays ?? newService.activeDays,
    };

    if (
      step.type == "information" ||
      step.type == "payment" ||
      step.type == "document"
    ) {
      temp1 = { ...temp1, modelId: null };
    }
    var res = await putData("admin/service-step/" + step.id, {
      ...temp1,
      serviceId: newService.id,
    });
    if (res.status == 200) {
      var temp = newService.steps;
      temp[i] = { ...res.data.data, isEdit: false, blocks: temp[i].blocks };
      setNewService({ ...newService, steps: temp });
      Modal.success({ title: res.data.message });
      tags.map(async (tag) => {
        if (!tag.id) {
          var res = await postData("admin/service-metadata", tag);
        }
      });
      deletedTags.map(async (tag) => {
        if (tag.id) {
          var res = await deleteData(`admin/service-metadata/${tag.id}`);
        }
      });
    } else {
      Modal.error({ title: res });
    }
    setIsLoading(false);
  };
  const handleAddTag = () => {
    var tempValue = "referral";
    setTags([
      {
        type: "tag",
        modelType: "service_step",
        modelId: step.id ? step.id : 0,
        value: tempValue,
      },
    ]);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddTag();
    }
  };

  const getCondition = async (step, i) => {
    setLoading2(true);
    if (step.id) {
      var res = await fetchData(`admin/service-step/${step.id}/block`);
      var res2 = await fetchData(`admin/service-step/${step.id}/condition`);
      if (res.status == 200 && res2.status == 200) {
        var temp = newService.steps;
        var referral = res2.data.data.filter((a) => {
          return a.parameter == "referral_by";
        });
        if (referral.length == 0) {
          referral[0] = {};
        }
        var age = res2.data.data.filter((a) => {
          return a.parameter == "age";
        });
        temp[i] = {
          ...temp[i],
          blocks: res.data.data,
          condition: { referral: referral[0], age: age },
        };
        setNewService({ ...newService, steps: temp });
        setStep({
          ...step,
          blocks: res.data.data,
          condition: { referral: referral[0], age: age },
        });
      } else {
        setLoading2(false);
        Modal.error({ title: res });
        return false;
      }
    } else if (!step.blocks) {
      var temp = newService.steps;
      temp[i] = { ...temp[i], blocks: [] };
      setNewService({ ...newService, steps: temp });
      setStep({ ...step, blocks: res.data.data });
    }
    setLoading2(false);
    return true;
  };
  return (
    <>
      <div
        id={"step" + i}
        className={`d-flex ${isMobile ? "flex-column" : "flex-row gap-3"} `}
      >
        <div
          style={isMobile ? { width: "100%" } : { width: "5%" }}
          className={`d-flex flex-row`}
        >
          <div
            className={`d-flex ${
              isMobile ? "flex-row col-10 px-4" : "flex-column"
            } gap-4`}
          >
            {privilage.indexOf(access.serviceManegment.editStep) > -1 &&
              i != 0 && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginTop: "7px" }}
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                  onClick={async () => {
                    setLoading3(true);
                    var temp = newService.steps;
                    var mytemp = { ...temp[i], priority: i - 1 };
                    temp[i] = { ...temp[i - 1], priority: i };
                    temp[i - 1] = mytemp;
                    await setNewService({ ...newService, steps: temp });
                    if (temp[i].id) {
                      var res = await putData(
                        "admin/service-step/" + temp[i].id,
                        {
                          ...temp[i],
                          serviceId: newService.id,
                        }
                      );
                      if (res.status == 200 && temp[i - 1].id) {
                        var res2 = await putData(
                          "admin/service-step/" + temp[i - 1].id,
                          {
                            ...temp[i - 1],
                            serviceId: newService.id,
                          }
                        );
                        if (res2.status == 200) {
                          Modal.success({ title: res2.data.message });
                        }
                      }
                    }
                    setLoading3(false);
                  }}
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"
                  />
                </svg>
                // <svg
                //   style={{ marginTop: "7px" }}
                //   width="10"
                //   height="19"
                //   viewBox="0 0 10 19"
                //   fill="none"
                //   xmlns="http://www.w3.org/2000/svg"
                // onClick={async () => {
                //   setLoading3(true);
                //   var temp = newService.steps;
                //   var mytemp = { ...temp[i], priority: i - 1 };
                //   temp[i] = { ...temp[i - 1], priority: i };
                //   temp[i - 1] = mytemp;
                //   await setNewService({ ...newService, steps: temp });
                //   if (temp[i].id) {
                //     var res = await putData(
                //       "admin/service-step/" + temp[i].id,
                //       {
                //         ...temp[i],
                //         serviceId: newService.id,
                //       }
                //     );
                //     if (res.status == 200 && temp[i - 1].id) {
                //       var res2 = await putData(
                //         "admin/service-step/" + temp[i - 1].id,
                //         {
                //           ...temp[i - 1],
                //           serviceId: newService.id,
                //         }
                //       );
                //       if (res2.status == 200) {
                //         Modal.success({ title: res2.data.message });
                //       }
                //     }
                //   }
                //   setLoading3(false);
                // }}
                // >
                //   <path
                //     fill-rule="evenodd"
                //     clip-rule="evenodd"
                //     d="M8.22581 14.3279H10L7.09677 19L4.19355 14.3279H5.96774V6.54098H8.22581V14.3279ZM1.77419 4.67213H0L2.90323 0L5.80645 4.67213H4.03226V12.459H1.77419V4.67213Z"
                //     fill="#4C4C4C"
                //   />
                // </svg>
              )}
            {!isLoading &&
            privilage.indexOf(access.serviceManegment.deleteStep) > -1 ? (
              <svg
                style={isMobile ? { marginTop: "7px" } : {}}
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="15"
                viewBox="0 0 12 15"
                fill="none"
                onClick={async () => {
                  await deleteStep(newService.steps[i]);
                }}
              >
                <path
                  className="delete-icon"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.38462 3.3857C1.254 3.3857 1.12938 3.44742 1.04169 3.55542C0.954456 3.66342 0.912002 3.80793 0.925387 3.95296C0.925387 3.95296 1.56 10.7904 1.79446 13.316C1.86738 14.1024 2.46231 14.7 3.1717 14.7C4.59093 14.7 7.46262 14.7 8.88554 14.7C9.59862 14.7 10.1954 14.0962 10.2637 13.3052C10.4834 10.7744 11.0746 3.94936 11.0746 3.94936C11.0871 3.80536 11.0446 3.66136 10.9569 3.55439C10.8697 3.4469 10.7455 3.3857 10.6154 3.3857H1.38462ZM6.92308 6.47142V11.6143C6.92308 11.8982 7.12985 12.1286 7.38462 12.1286C7.63938 12.1286 7.84615 11.8982 7.84615 11.6143V6.47142C7.84615 6.18753 7.63938 5.95713 7.38462 5.95713C7.12985 5.95713 6.92308 6.18753 6.92308 6.47142ZM4.15385 6.47142V11.6143C4.15385 11.8982 4.36062 12.1286 4.61538 12.1286C4.87015 12.1286 5.07692 11.8982 5.07692 11.6143V6.47142C5.07692 6.18753 4.87015 5.95713 4.61538 5.95713C4.36062 5.95713 4.15385 6.18753 4.15385 6.47142ZM8.61553 1.84284L8.39354 1.24936C8.17846 0.674388 7.67446 0.299988 7.11553 0.299988C6.486 0.299988 5.514 0.299988 4.88447 0.299988C4.32554 0.299988 3.82154 0.674388 3.60646 1.24936L3.38447 1.84284H0.428763C0.191993 1.84284 0 2.07324 0 2.35713C0 2.64102 0.191993 2.87142 0.428763 2.87142H11.5712C11.808 2.87142 12 2.64102 12 2.35713C12 2.07324 11.808 1.84284 11.5712 1.84284H8.61553Z"
                  fill="#4C4C4C"
                />
              </svg>
            ) : (
              <Spin />
            )}
            {!isLoading &&
            privilage.indexOf(access.serviceManegment.editStep) > -1 ? (
              newService.steps[i].hide ? (
                <svg
                  width="12"
                  height="7"
                  viewBox="0 0 12 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={
                    isMobile ? { marginTop: "7px" } : { cursor: "pointer" }
                  }
                  onClick={() => {
                    let temp = newService.steps;
                    temp[i].hide = !temp[i].hide;
                    setNewService({ ...newService, steps: temp });
                    if (temp[i].id) editStep(newService.steps[i], i);
                  }}
                >
                  <path
                    d="M11.9243 3.0305C11.3436 2.27283 9.31159 0 6.00472 0C2.69786 0 0.678397 2.27287 0.0851807 3.0305C-0.0283936 3.1694 -0.0283936 3.37145 0.0851807 3.51035C0.66582 4.26802 2.69786 6.54084 6.00472 6.54084C9.31159 6.54084 11.331 4.26797 11.9243 3.51035C12.0252 3.37145 12.0252 3.16939 11.9243 3.0305ZM6.00472 5.70744C4.65416 5.70744 3.56875 4.62151 3.56875 3.27041C3.56875 1.91927 4.65421 0.833386 6.00472 0.833386C7.35528 0.833386 8.44069 1.91931 8.44069 3.27041C8.44069 4.62155 7.35532 5.70744 6.00472 5.70744Z"
                    fill="#4C4C4C"
                  />
                  <path
                    d="M7.80367 3.27322C7.80367 4.26793 6.99765 5.07426 6.0034 5.07426C5.00914 5.07426 4.20312 4.2679 4.20312 3.27322C4.20312 2.27853 5.00914 1.47217 6.0034 1.47217C6.99765 1.47217 7.80367 2.27853 7.80367 3.27322Z"
                    fill="#4C4C4C"
                  />
                </svg>
              ) : (
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={
                    isMobile ? { marginTop: "7px" } : { cursor: "pointer" }
                  }
                  onClick={() => {
                    let temp = newService.steps;
                    temp[i].hide = !temp[i].hide;
                    setNewService({ ...newService, steps: temp });
                    if (temp[i].id) editStep(newService.steps[i], i);
                  }}
                >
                  <path
                    d="M12.9246 7.03601C12.5834 6.58475 11.7244 5.61952 10.3978 4.89246L9.15964 6.12088C9.34912 6.45935 9.45021 6.86043 9.45021 7.27411C9.45021 8.61542 8.36368 9.6934 7.01183 9.6934C6.59491 9.6934 6.19066 9.59311 5.8495 9.40511L5.01562 10.2325C5.62204 10.4079 6.27903 10.5207 7.01181 10.5207C10.3219 10.5207 12.3434 8.26441 12.9372 7.5123C13.0256 7.37441 13.0256 7.17392 12.9245 7.03604L12.9246 7.03601Z"
                    fill="#4C4C4C"
                  />
                  <path
                    d="M7.0008 9.02904C7.97365 9.02904 8.76953 8.23931 8.76953 7.27417C8.76953 7.04858 8.71903 6.8229 8.64315 6.62231L6.34375 8.90371C6.546 8.97891 6.77347 9.02901 7.00083 9.02901L7.0008 9.02904Z"
                    fill="#4C4C4C"
                  />
                  <path
                    d="M11.66 2.12224C11.4957 1.95925 11.2305 1.95925 11.0662 2.12224L8.89314 4.27829C8.32463 4.1153 7.69293 4.015 7.01065 4.015C3.70052 4.015 1.67907 6.27133 1.08526 7.02344C0.971578 7.16133 0.971578 7.36191 1.08526 7.4998C1.42641 7.93857 2.2476 8.87871 3.52364 9.59317L1.818 11.2855C1.65373 11.4484 1.65373 11.7116 1.818 11.8746C1.90648 11.9624 2.00747 12 2.12124 12C2.23492 12 2.336 11.9624 2.42448 11.8746L11.6726 2.69888C11.8243 2.56099 11.8243 2.28522 11.66 2.12231L11.66 2.12224ZM4.55966 7.27419C4.55966 5.93288 5.6588 4.8549 6.99803 4.8549C7.37708 4.8549 7.74342 4.94269 8.07198 5.10558L7.55396 5.61955C7.37708 5.56944 7.2002 5.53176 6.99803 5.53176C6.02519 5.53176 5.22931 6.3215 5.22931 7.28664C5.22931 7.47463 5.2672 7.66271 5.31779 7.8382L4.79977 8.35217C4.6481 8.0137 4.5597 7.65013 4.5597 7.27415L4.55966 7.27419Z"
                    fill="#4C4C4C"
                  />
                </svg>
              )
            ) : (
              <Spin />
            )}
            {!newService.steps[i].isEdit &&
              privilage.indexOf(access.serviceManegment.editStep) > -1 && (
                <h4
                  style={{
                    color: "#2E6CDF",
                    textAlign: "center",
                    alignSelf: "center",
                    fontSize: "14px",
                    fontWeight: "700",
                    marginLeft: "-3px",
                    marginTop: "-5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    var temp = newService.steps;
                    temp[i] = { ...temp[i], isEdit: true };
                    setNewService({ ...newService, steps: temp });
                  }}
                >
                  Edit
                </h4>
              )}
          </div>

          <span
            style={{
              color: " rgba(46, 108, 223, 1)",
              fontSize: "16px",
              fontWeight: "700",
              marginTop: "7px",
              marginLeft: "15px",
            }}
          >
            {i + 1}
          </span>
        </div>
        <div
          style={
            isMobile
              ? newService.steps[i].hide
                ? { opacity: "0.25", width: "100%" }
                : { width: "100%" }
              : newService.steps[i].hide
              ? { opacity: "0.25", width: "50%" }
              : { width: "50%" }
          }
          className="px-2 "
        >
          <div className="d-flex flex-row  mb-4 align-items-center">
            <span className="col-3" style={{ display: "flex" }}>
              <div
                style={{
                  color: "#ff4d4f",
                  fontSize: "18px",
                  marginRight: "5px",
                }}
              >
                *
              </div>
              Title:{" "}
            </span>

            <Input
              className="col-9"
              value={newService.steps[i].title}
              disabled={!newService.steps[i].isEdit}
              onChange={(e) => {
                var temp = newService.steps;
                temp[i] = {
                  ...temp[i],
                  title: e.target.value,
                };
                setNewService({ ...newService, steps: temp });
              }}
            />
          </div>
          <div className="d-flex flex-row mb-4 align-items-center">
            <span className="col-3" style={{ display: "flex" }}>
              <div
                style={{
                  color: "#ff4d4f",
                  fontSize: "18px",
                  marginRight: "5px",
                }}
              >
                *
              </div>
              Type:{" "}
            </span>

            <Select
              className="col-9"
              value={newService.steps[i].type}
              disabled={!newService.steps[i].isEdit}
              onChange={(value) => {
                var temp = newService.steps;
                temp[i] = { ...temp[i], type: value };
                setNewService({ ...newService, steps: temp });
              }}
            >
              <Select.Option value="appointment">Appointment</Select.Option>
              <Select.Option value="form">Form</Select.Option>
              <Select.Option value="document">Document</Select.Option>
              <Select.Option value="information">Information</Select.Option>
              <Select.Option value="payment">Payment</Select.Option>
              <Select.Option value="sign_form">Sign Form</Select.Option>
            </Select>
          </div>

          {!loading3 &&
            (newService.steps[i].type == "form" ||
              newService.steps[i].type == "appointment" ||
              newService.steps[i].type == "sign_form") && (
              <div className="d-flex flex-row  mb-4 align-items-center">
                <span className="col-3" style={{ display: "flex" }}>
                  <div
                    style={{
                      color: "#ff4d4f",
                      fontSize: "18px",
                      marginRight: "5px",
                    }}
                  >
                    *
                  </div>
                  Event:{" "}
                </span>

                <Select
                  className="col-9"
                  value={
                    newService.steps[i].modelId
                      ? newService.steps[i].modelId
                      : null
                  }
                  disabled={!newService.steps[i].isEdit}
                  onChange={(value) => {
                    var temp = newService.steps;
                    temp[i] = { ...temp[i], modelId: value };
                    setNewService({
                      ...newService,
                      steps: temp,
                    });
                  }}
                >
                  {event[newService.steps[i].type]
                    ? event[newService.steps[i].type].map((e) => {
                        return e ? (
                          <Select.Option value={e.id}>{e.title}</Select.Option>
                        ) : null;
                      })
                    : null}
                </Select>
              </div>
            )}
          {!loading3 && newService.steps[i].type == "payment" && (
            <div className="d-flex flex-row mb-4 align-items-center">
              <span className="col-3" style={{ display: "flex" }}>
                <div
                  style={{
                    color: "#ff4d4f",
                    fontSize: "18px",
                    marginRight: "5px",
                  }}
                >
                  *
                </div>
                Price:{" "}
              </span>

              <InputNumber
                min={0}
                className="col-9"
                value={newService.steps[i].price}
                disabled={!newService.steps[i].isEdit}
                onChange={(e) => {
                  var temp = newService.steps;
                  temp[i] = {
                    ...temp[i],
                    price: e,
                  };
                  setNewService({ ...newService, steps: temp });
                }}
              />
            </div>
          )}

          <div className="d-flex flex-row mb-4 align-items-center">
            <span className="col-3" style={{ display: "flex" }}>
              <div
                style={{
                  color: "#ff4d4f",
                  fontSize: "18px",
                  marginRight: "5px",
                }}
              >
                *
              </div>
              Details:{" "}
            </span>

            <Input.TextArea
              className="col-9"
              value={newService.steps[i].description}
              disabled={!newService.steps[i].isEdit}
              onChange={(e) => {
                var temp = newService.steps;
                temp[i] = {
                  ...temp[i],
                  description: e.target.value,
                };
                setNewService({ ...newService, steps: temp });
              }}
            />
          </div>
          {/* <div className="d-flex flex-row  mb-4 align-items-center">
            <span className="col-3" style={{ display: "flex" }}>
              Tag:
            </span>

            <Input
              className="col-9"
              disabled={!newService.steps[i].isEdit}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={handleKeyPress}
              suffix={
                <Button
                  disabled={!newService.steps[i].isEdit}
                  size="small"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={handleAddTag}
                />
              }
            />
          </div> */}
          {/* <div className="d-flex flex-row mb-4 align-items-center">
            <span className="col-3" style={{ display: "flex" }}></span>

            <div style={{ display: "block" }} className="col-9">
              {tags.map((tag, index) => (
                <Tag
                  key={index}
                  closable={newService.steps[i].isEdit}
                  onClose={() => {
                    if (tag.id) {
                      setDeletedTags((prevDeletedTags) => [
                        ...prevDeletedTags,
                        tag,
                      ]);
                    }
                    setTags(tags.filter((_, i) => i !== index));
                  }}
                >
                  {tag.value}
                </Tag>
              ))}
            </div>
          </div> */}
        </div>
        <div
          style={
            isMobile
              ? newService.steps[i].hide
                ? { opacity: "0.25", width: "100%" }
                : { width: "100%" }
              : newService.steps[i].hide
              ? { opacity: "0.25", width: "45%", paddingLeft: "35px" }
              : { width: "45%", paddingLeft: "35px" }
          }
          className={`${isMobile ? "px-2" : ""}`}
        >
          <FormItem
            // labelCol={12}
            label={"Starts at"}
            name={`start` + i}
            initialValue={newService.steps[i].startDays}
            // rules={[{ required: true, message: "Please add start days!" }]}
          >
            <InputNumber
              min={0}
              value={newService.steps[i].startDays}
              disabled={!newService.steps[i].isEdit}
              keyboard={true}
              onChange={(e) => {
                var temp = newService.steps;
                temp[i] = { ...temp[i], startDays: e };
                setNewService({ ...newService, steps: temp });
              }}
            />
            <span style={{ marginLeft: "8px" }}> days after activate.</span>
          </FormItem>
          <FormItem
            // rules={[{ required: true, message: "Please add expires days!" }]}
            style={{ marginBottom: "18px" }}
            label={"Expires at"}
            name={`expire` + i}
            initialValue={newService.steps[i].expireDays}
          >
            <InputNumber
              min={0}
              value={newService.steps[i].expireDays}
              disabled={!newService.steps[i].isEdit}
              keyboard={true}
              onChange={(e) => {
                var temp = newService.steps;
                temp[i] = { ...temp[i], expireDays: e };
                setNewService({ ...newService, steps: temp });
              }}
            />
            <span style={{ marginLeft: "8px" }}> days after starting day.</span>
          </FormItem>

          <Form.Item
            style={{
              marginBottom: "12px",
              width: "100%",
            }}
            label="Requires admin’s approval"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 8 }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span></span>

              <Switch
                checked={newService.steps[i].needAdminApproved}
                onChange={(v) => {
                  var temp = newService.steps;
                  temp[i] = {
                    ...temp[i],
                    needAdminApproved: v,
                  };
                  setNewService({ ...newService, steps: temp });
                }}
                disabled={!newService.steps[i].isEdit}
              />
            </div>
          </Form.Item>

          <Form.Item
            style={{
              marginBottom: "12px",
              width: "100%",
            }}
            label="Requires user’s approval"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 8 }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span></span>
              <Switch
                checked={newService.steps[i].needUserApproved}
                onChange={(v) => {
                  var temp = newService.steps;
                  temp[i] = {
                    ...temp[i],
                    needUserApproved: v,
                  };
                  setNewService({ ...newService, steps: temp });
                }}
                disabled={!newService.steps[i].isEdit}
              />
            </div>
          </Form.Item>

          {step.type == "information" && (
            <Form.Item
              style={{
                marginBottom: "12px",
                width: "100%",
              }}
              label="Does this step have a referral tag"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 8 }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span></span>
                <Switch
                  checked={tags.length == 1}
                  onChange={(v) => {
                    if (v) {
                      handleAddTag();
                    } else {
                      setTags([]);
                      if (tags[0].id) {
                        setDeletedTags([tags[0]]);
                      }
                    }
                  }}
                  disabled={!newService.steps[i].isEdit}
                />
              </div>
            </Form.Item>
          )}

          {loading2 ? (
            <Spin />
          ) : (
            privilage.indexOf(access.serviceManegment.getCondition) > -1 &&
            newService.steps[i].id && (
              <h2
                style={
                  newService.steps[i].isEdit
                    ? {
                        color: "#2E6CDF",
                        fontWeight: "700",
                        marginTop: "-8px",
                        cursor: "pointer",
                      }
                    : {
                        color: "rgba(106, 105, 105, 1)",
                        fontWeight: "700",
                        marginTop: "10px",
                        cursor: "pointer",
                      }
                }
                onClick={async () => {
                  if (newService.steps[i].isEdit) {
                    var res = await getCondition(newService.steps[i]);
                    if (res) {
                      setAddCond(true);
                    }
                  }
                }}
              >
                Conditions
              </h2>
            )
          )}
        </div>
      </div>
      {newService.steps[i].isEdit &&
        privilage.indexOf(access.serviceManegment.addStep) > -1 &&
        privilage.indexOf(access.serviceManegment.editStep) > -1 && (
          <Button
            style={
              isMobile
                ? { marginBottom: "20px", width: "25%", marginTop: "30px" }
                : {
                    marginLeft: "8%",
                    marginBottom: "20px",
                    width: "10%",
                  }
            }
            loading={isLoading && newService.steps[i].isEdit}
            onClick={() => {
              if (newService.steps[i].isEdit && !newService.steps[i].id)
                postStep(newService.steps[i], i);
              if (newService.steps[i].isEdit && newService.steps[i].id)
                editStep(newService.steps[i], i);
            }}
            type={newService.steps[i].isEdit ? "primary" : "default"}
          >
            {newService.steps[i].id ? "Save" : "Add"}
          </Button>
        )}
      <div className="h-line" />
    </>
  );
};

export default Step;
