import { React, useEffect, useState } from "react";
import "./styles.scss";
import { Spin, Tabs, Form, Input, Button, Modal, Dropdown } from "antd";
import { AppstoreFilled } from "@ant-design/icons";
import { useAssets, useMobile } from "../../hooks/index";
import NotificationSetting from "./notification/notification";
import { fetchData, putData } from "../../endpoints";
import { isValidPhoneNumber } from "react-phone-number-input";
const Setting = () => {
  const isMobile = useMobile();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const { getFile } = useAssets();
  const [navMobileItem, setNavMobileItem] = useState(0);
  const items = [
    {
      key: "1",
      label: (
        <button
          className={`menu-btn-mobile-item ${
            navMobileItem === 0 && "menu-btn-mobile-item-active"
          }`}
          onClick={() => setNavMobileItem(0)}
        >
          Account Settings
        </button>
      ),
    },
    {
      key: "2",
      label: (
        <button
          className={`menu-btn-mobile-item ${
            navMobileItem === 1 && "menu-btn-mobile-item-active"
          }`}
          onClick={() => setNavMobileItem(1)}
        >
          Notification Settings
        </button>
      ),
    },
  ];
  const getUser = async () => {
    setLoading(true);
    var res = await fetchData(`user`);
    if (res.status === 200) {
      setUser(res.data.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    getUser();
  }, []);
  return (
    <div
      style={
        !isMobile
          ? { backgroundColor: "white", minHeight: "100vh" }
          : {
              backgroundColor: "white",
              minHeight: "100vh",
              paddingRight: "5%",
              paddingLeft: "5%",
              // marginTop: "70px",
            }
      }
      className="d-flex flex-column w-100 py-3"
    >
      <div
        style={
          isMobile
            ? { paddingLeft: "0px", alignItems: "center" }
            : { paddingLeft: "55px", alignItems: "center" }
        }
        className={`d-flex flex-row ${isMobile ? "gap-3" : " gap-4"}`}
      >
        <img
          src={getFile("Profile")}
          width={isMobile ? "70px" : "100px"}
          height={isMobile ? "70px" : "100px"}
          style={{ borderRadius: "45px" }}
        />
        <div
          className={`d-flex flex-column ${isMobile && "col-6"}`}
          style={{ justifyContent: "center" }}
        >
          {loading ? (
            <Spin />
          ) : (
            <span
              style={
                isMobile
                  ? { fontWeight: "700", fontSize: "16px" }
                  : { fontWeight: "700", fontSize: "20px" }
              }
            >
              {user.firstName + " " + user.lastName}
            </span>
          )}
          <span
            style={
              isMobile
                ? {
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "rgba(161, 129, 58, 1)",
                    marginTop: "0.2rem",
                  }
                : {
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgba(161, 129, 58, 1)",
                  }
            }
          >
            Admin
          </span>
        </div>
        {isMobile && (
          <Dropdown menu={{ items }} placement="bottomLeft">
            <button
              className="menu-btn-mobile"
              style={{ marginBottom: "40px" }}
            >
              <AppstoreFilled style={{ fontSize: "20px" }} />
            </button>
          </Dropdown>
        )}
      </div>{" "}
      {isMobile && <hr style={{ margin: "5% 5%" }}></hr>}
      <div
        style={
          isMobile
            ? { width: "100%", marginTop: "10px" }
            : { width: "93%", marginTop: "-20px" }
        }
        className="top-tabs d-flex flex-row "
        dir="rtl"
      >
        {!isMobile && (
          <Tabs
            size={isMobile ? "small" : "larg"}
            tabBarGutter={18}
            style={{ color: "black", fontWeight: "500", width: "97%" }}
            defaultActiveKey={1}
            tabPosition="top"
            className="tabbar-account"
            direction="rtl"
            type="card"
            items={[
              {
                label: `Notifications`,
                key: 1,
                children: <NotificationSetting isMobile={isMobile} />,
              },
              {
                label: `Account`,
                key: 2,
                children: (
                  <AccountSettings
                    user={user}
                    setUser={setUser}
                    loading={loading}
                  />
                ),
              },
            ]}
          />
        )}
      </div>
      {isMobile && (
        <div>
          {navMobileItem === 0 && (
            <AccountSettings user={user} setUser={setUser} loading={loading} />
          )}
          {navMobileItem === 1 && <NotificationSetting />}
        </div>
      )}
    </div>
  );
};
const AccountSettings = ({ user, setUser, loading }) => {
  const [edit, setEdit] = useState(false);
  const [changh, setChangh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const isMobile = useMobile();
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [form] = Form.useForm();

  const validateEmail = (email) => {
    return email
      .toLowerCase()
      .match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  };
  const save = async () => {
    let regex = /.*\d.*/;
    if (regex.test(user.firstName) || regex.test(user.lastName)) {
      Modal.error({ title: "firstname & lastname can't includes number" });
      return;
    }
    if (!isValidPhoneNumber(user.phoneNumber)) {
      Modal.error({
        title: "Phone number must be valid with the country code.",
      });
      return;
    }
    if (!validateEmail(user.email)) {
      Modal.error({ title: "Email must be a valid!" });
      return;
    }
    setIsLoading(true);
    var res = await putData("user", user);
    if (res.status == 200) {
      setEdit(false);
      Modal.success({
        title: "Account information has been successfully updated!",
      });
    } else {
      Modal.error({ title: res });
    }
    setIsLoading(false);
  };
  const changhPassword = async () => {
    setIsLoading(true);
    if (newPassword !== newPasswordConfirmation) {
      Modal.error({ title: "New Password and Confirmation does not match!" });
      setIsLoading(false);
      return;
    }
    if (!newPassword || !newPasswordConfirmation) {
      Modal.error({
        title: "Please enter your New password and Confirmation! ",
      });
      setIsLoading(false);
      return;
    }
    if (!password) {
      Modal.error({ title: "Please enter your current password! " });

      setIsLoading(false);
      return;
    }
    if (password.length < 8) {
      Modal.error({
        title: "OldPassword must be longer than or equal to 8 characters",
      });
      setIsLoading(false);
      return;
    }
    if (newPassword === password) {
      Modal.error({
        title: "New password and old password can't be same",
      });
      setIsLoading(false);
      return;
    }
    if (newPassword.length < 8) {
      Modal.error({
        title: "New password must be longer than or equal to 8 characters",
      });
      setIsLoading(false);
      return;
    }

    var res = await putData("user/change-password", {
      oldPassword: password,
      newPassword: newPassword,
      newPasswordConfirmation: newPasswordConfirmation,
    });
    if (res.status == 200) {
      setChangh(false);
      form.setFieldValue("password", "");
      form.setFieldValue("newPassword", "");
      form.setFieldValue("newPasswordConfirmation", "");
      setPassword("");
      setNewPassword("");
      setNewPasswordConfirmation("");
      Modal.success({
        title: "Password have been successfully changed!",
      });
    } else {
      Modal.error({ title: res });
    }
    setIsLoading(false);
  };
  return (
    <div
      style={isMobile ? {} : { marginLeft: "-40px" }}
      className={`${isMobile ? "py-1 " : "d-flex flex-row gap-4 py-3 px-5"}`}
      dir="ltr"
    >
      <div
        className={`${
          isMobile
            ? "d-flex flex-column gap-1 col-12 px-3"
            : "d-flex flex-column gap-3 col-6 px-3"
        }`}
      >
        <div className="d-flex flex-row w-100">
          <h2
            style={
              isMobile
                ? { fontSize: "16px", fontWeight: "700" }
                : { fontSize: "20px", fontWeight: "700" }
            }
            className="col-10"
          >
            Account Information
          </h2>
        </div>
        {loading ? (
          <div dir="rtl" className="w-100 py-5">
            {" "}
            <Spin size="larg" />
          </div>
        ) : (
          <Form
            form={form}
            name="accountInfo"
            layout="vertical"
            size="large"
            style={{ maxWidth: "480px" }}
            initialValues={{
              email: user.email,
              firstName: user.firstName,
              password: "",
            }}
          >
            <Form.Item
              name={"email"}
              label="Email"
              rules={[
                { required: true, message: "Please enter an email address!" },
              ]}
            >
              <Input
                disabled={!edit}
                onChange={(e) => {
                  setUser({ ...user, email: e.target.value });
                }}
              />
            </Form.Item>

            <Form.Item
              name={"firstName"}
              label="First Name"
              rules={[
                { required: true, message: "Please fill out the firstname!" },
              ]}
            >
              <Input
                disabled={!edit}
                onChange={(e) => {
                  setUser({ ...user, firstName: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item
              name={"password"}
              label={
                <div>
                  Password{" "}
                  {!changh ? (
                    <svg
                      onClick={() => {
                        setChangh(true);
                      }}
                      style={{ marginLeft: "5px" }}
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.5">
                        <path
                          d="M10.4909 5.06374L10.6823 5.2476L3.65931 12.0005H0.644531V9.10175L7.6675 2.34859L10.2998 4.87968L10.4909 5.06374Z"
                          fill="#18324E"
                        />
                        <path
                          d="M12.8645 3.14936L11.3195 4.63499L8.30469 1.73616L9.84974 0.250534C10.1973 -0.0835112 10.7628 -0.0835112 11.1102 0.250534L12.8647 1.93749C13.2119 2.27141 13.2119 2.81509 12.8645 3.14936H12.8645Z"
                          fill="#18324E"
                        />
                      </g>
                    </svg>
                  ) : (
                    <Button
                      style={{ marginLeft: "5px" }}
                      size="small"
                      loading={isLoading}
                      onClick={changhPassword}
                    >
                      Save
                    </Button>
                  )}
                </div>
              }
              rules={[
                {
                  required: true,
                  message: "Please enter the Current Password!",
                },
              ]}
            >
              {!changh && (
                <Input
                  disabled={!changh}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={""}
                />
              )}
              {changh && (
                <Input.Password
                  disabled={!changh}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                />
              )}
            </Form.Item>
            {changh && (
              <>
                <Form.Item
                  label="New Password"
                  name={"newPassword"}
                  rules={[
                    { required: true, message: "Please enter a new password!" },
                  ]}
                >
                  <Input.Password
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                    value={newPassword}
                  />
                </Form.Item>
                <Form.Item
                  label="New Password Confirmation"
                  name={"newPasswordConfirmation"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter  New Password Confirmation!",
                    },
                  ]}
                >
                  <Input.Password
                    onChange={(e) => {
                      setNewPasswordConfirmation(e.target.value);
                    }}
                    value={newPasswordConfirmation}
                  />
                </Form.Item>
              </>
            )}
          </Form>
        )}
      </div>
      <div
        className={`${
          isMobile
            ? "d-flex flex-column gap-3 col-12 px-3"
            : "d-flex flex-column gap-3 col-6 px-3"
        }`}
      >
        <div className="d-flex flex-row w-100 my-1" dir="rtl">
          {!isMobile && (
            <Button
              type="primary"
              className="col-2"
              onClick={() => {
                edit ? save() : setEdit(true);
              }}
              loading={isLoading}
            >
              {edit ? "Save" : "Edit"}
            </Button>
          )}
        </div>

        {!loading && (
          <Form
            size="large"
            name="myForm"
            layout="vertical"
            style={{ maxWidth: "480px" }}
            initialValues={{
              phoneNumber: user.phoneNumber,
              lastName: user.lastName,
            }}
          >
            <Form.Item name={"phoneNumber"} label="Phone Number">
              <Input
                disabled={!edit}
                onChange={(e) => {
                  setUser({ ...user, phoneNumber: e.target.value });
                }}
              />
            </Form.Item>

            <Form.Item
              name={"lastName"}
              label={"Last Name"}
              rules={[
                { required: true, message: "Please fill out the lastname!" },
              ]}
            >
              <Input
                disabled={!edit}
                onChange={(e) => {
                  setUser({ ...user, lastName: e.target.value });
                }}
              />
            </Form.Item>
          </Form>
        )}
        {isMobile && (
          <Button
            type="primary"
            className="col-4"
            onClick={() => {
              edit ? save() : setEdit(true);
            }}
            loading={isLoading}
          >
            {edit ? "Save" : "Edit"}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Setting;
