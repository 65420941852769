var accessList = {
  userManegment: {
    showUser: "show_user",
    indexUser: "index_user",
    disableUser: "disable_user",
    enableUser: "enable_user",
    addUser: "create_user",
    editUser: "update_user",
    showProfile: "show_user_profile",
    editProfile: "update_user_profile",
    showDivice: "index_otp",
    logOutDivice: "delete_device",
    showPatient: "show_patient",
    addPatient: "create_patient",
    verifyPatient: "verify_patient",
    disablePatient: "disable_patient",
    indexPatient: "index_patient",
    deleteUser: "delete_user",
    createUserProfile: "create_user_profile",
    indexUserProfile: "index_user_profile",
    createUserAssignment: "create_user_assignment",
    showUserAssignment: "show_user_assignment",
    indexUserAssignments: "index_user_assignments",
    deleteUserAssignment: "delete_user_assignment",
  },
  patientManegment: {
    showPatient: "show_patient",
    indexPatient: "index_patient",
    disablePatient: "disable_patient",
    addPatient: "create_patient",
    verifyPatient: "verify_patient",
    deletePatient: "delete_patient",
    enablePatient: "enable_patient",
    uploadPatientPhoto: "upload_patient_photo",
    deletePatientPhoto: "delete_patient_photo",
    editPatient: {
      personal: "update_patient",
      serviceType: "update_patient_service_type",
      teacher: "update_patient_teacher",
      refferral: "update_patient_referral_by",
      emerjency: "update_patient_emergency_contact",
      deletePatientEmergencyContact: "delete_patient_emergency_contact",
      createPatientEmergencyContact: "create_patient_emergency_contact",
      createPatientServiceType: "create_patient_service_type",
      deletePatientServiceType: "delete_patient_service_type",
      createPatientTeacher: "create_patient_teacher",
      deletePatientTeacher: "delete_patient_teacher",
      createPatientReferralBy: "create_patient_referral_by",
      deletePatientReferralBy: "delete_patient_referral_by",
    },
    form: {
      indexPatientForm: "index_patient_form",
      showForm: "index_form",
      assignForm: "update_form_access",
      shareForm: "share_form",
      indexJotformForm: "index_jotform_form",
      createFormAccess: "create_form_access",
      showFormAccess: "show_form_access",
      indexFormAccess: "index_form_access",
      deleteFormAccess: "delete_form_access",
      createFormSubmission: "create_form_submission",
      createFormSubmission: "create_form_submission",
      indexFormSubmission: "index_form_submission",
      updateFormSubmission: "update_form_submission",
      deleteFormSubmission: "delete_form_submission",
      createSignForm: "create_sign_form",
      showSignForm: "show_sign_form",
      indexSignForm: "index_sign_form",
      updateSignForm: "update_sign_form",
      deleteSignForm: "delete_sign_form",
      indexBoxSignTemplates: "index_box_sign_templates",
    },
    document: {
      showDocument: "index_document",
      addDocument: "create_document",
      editDocument: "update_document",
      removeDocument: "delete_document",
    },
    service: {
      indexService: "index_service",
      indexpurchase: "index_purchase",
      duplicateService: "duplicate_service",
      createNewVersionForService: "create_new_version_for_service",
      indexPurchasePayments: "index_purchase_payments",
      showService: "show_service",
      showpurchase: "show_purchase",
      addService: "create_service",
      cancelPurchase: "cancel_purchase",
      editpurchase: "update_purchase",
      updatePurchaseStepStatus: "update_purchase_step_status",
      done: "approve_purchase",
      cancel: "cancel_purchase",
      purchaseService: "purchase_service",
      uploadServiceCover: "upload_service_cover",
      publishService: "publish_service",
      createServiceType: "create_service_type",
      showServiceType: "show_service_type",
      indexServiceType: "index_service_type",
      updateServiceType: "update_service_type",
      deleteServiceType: "delete_service_type",
      checkPurchaseBlockingConditions: "check_purchase_blocking_conditions",
      step: {
        indexCalendlyEvent: "index_calendly_event",
        indexCalendlyUser: "index_calendly_user",
        editStatus: "update_purchase_step_status",
        showReply: "index_purchase_reply",
        addReply: "create_purchase_reply",
        editReply: "update_purchase_reply",
        deleteReply: "delete_purchase_reply",
        readReply: "read_purchase_reply",
        showApoointment: "show_appointment",
        showDocument: "index_document",
        approve: "approve_purchase_step",
        generateLink: "generate_purchase_appointment_step_url",
        bookNow: "show_appointment",
        rescchole: "show_appointment",
        cancel: "show_appointment",
        Resend: "resend_sign_form_request",
        sign: "resend_sign_form_request",
        preiew: "preview_sign_form_request",
        Reject: "decline_purchase_step",
        createServiceStep: "create_service_step",
        showServiceStep: "show_service_step",
        indexServiceStep: "index_service_step",
        updateServiceStep: "update_service_step",
        deleteServiceStep: "delete_service_step",
      },
    },
    report: {
      index: "index_report",
      show: "show_report",
      add: "create_report",
      addTemplate: "",
      showTemplate: "",
      delete: "delete_report",
      addSection: "create_report_section",
      editSection: "update_report_section",
      deleteSection: "delete_report_section",
      addQuestion: "create_report_section",
      editQuestion: "update_report_question",
      deleteQuestion: "delete_report_question",
      addCommand: "create_report_command",
      editCommand: "update_report_command",
      deleteCommand: "delete_report_command",
      runCommand: "",
      editResult: "",
      runResult: "",
      benchMark: "show_report_benchmark",
      upload: "update_report_result",
      indexReportTemplate: "index_report_template",
      cloneReport: "clone_report",
      importReport: "import_report",
      runReport: "run_report",
      exportReport: "export_report",
      uploadReportFinalResult: "upload_report_final_result",
      indexReportBenchmark: "index_report_benchmark",
      exportReportBenchmark: "export_report_benchmark",
      showReportCommand: "show_report_command",
      indexReportCommand: "index_report_command",
      runReportCommand: "run_report_command",
      qualityControlReportCommand: "quality_control_report_command",
      createReportQuestion: "create_report_question",
      showReportQuestion: "show_report_question",
      showReportSection: "show_report_section",
      indexReportSection: "index_report_section",
      runReportSection: "run_report_section",
      showReportVoice: "show_report_voice",
      uploadReportVoice: "upload_report_voice",
      deleteReportVoice: "delete_report_voice",
    },
    comment: {
      index: "index_comment",
    },
  },
  adminManegment: {
    indexPrivilage: "index_privilege",
    showRole: "show_role",
    indexRole: "index_role",
    showAdmin: "index_user",
    showPrivilege: "show_privilege",
    addPrivilage: "create_privilege",
    editPrivilage: "update_privilege",
    deletePrivilage: "delete_privilege",
    addRole: "create_role",
    editRole: "update_role",
    deleteRole: "delete_role",
    addAdmin: "update_role",
    deleteAdmin: "update_role",
    updateRole: "update_role",
    addPrivilegeToRole: "add_privilege_to_role",
    deletePrivilegeFromRole: "delete_privilege_from_role",
  },
  formManegment: {
    show: "show_form",
    index: "index_form",
    edit: "update_form",
    delete: "delete_form",
    assign: "update_form_access",
    add: "create_form",
  },
  eventManegment: {
    add: "create_appointment_event",
    edit: "update_appointment_event",
    index: "index_appointment_event",
    delete: "delete_appointment_event",
  },
  documentManegment: {
    add: "create_document",
    edit: "update_document",
    index: "index_document",
    delete: "delete_document",
    showDocument: "show_document",
    createDocumentCategory: "create_document_category",
    showDocumentCategory: "show_document_category",
    showDocumentCategory: "show_document_category",
    showDocumentCategory: "show_document_category",
    deleteDocumentCategory: "delete_document_category",
  },
  serviceManegment: {
    index: "index_service",
    show: "show_service",
    add: "create_service",
    edit: "update_service",
    delete: "delete_service",
    publish: "publish_service",
    addStep: "create_service_step",
    editStep: "update_service_step",
    addCondition: "create_service_step_condition",
    deleteStep: "delete_service_step",
    getCondition: "index_service_step_condition",
    deleteCondition: "delete_service_step_condition",
    deleteBlock: "delete_service_step_block",
    indexBlock: "index_service_step_block",
    createBlock: "create_service_step_block",
  },
  todoList: {
    showPatient: "show_patient",
    indexPatient: "index_patient",
    indexEvent: "index_appointment_event",
    verifyPatient: "verify_patient",
    showStep: "index_purchase_step",
    approve: "approve_purchase_step",
    reject: "decline_purchase_step",
    showappointment: "show_appointment",
    showEvent: "show_appointment_event",
    editAppointment: "update_appointment",
    addComment: "create_comment",
    check: "update_appointment",
    changeStatus: "update_purchase_step_status",
    addReply: "create_purchase_reply",
    showpurchase: "show_purchase",
    doneService: "approve_purchase",
    indexReply: "index_purchase_reply",
    indexUser: "index_user",
    readReply: "read_purchase_reply",
    indexComment: "index_comment",
    addComment: "create_comment",
    editComment: "update_comment",
    deleteComment: "delete_comment",
    deleteAttachment: "delete_comment_attachment",
    addAttachment: "create_comment_attachment",
    showComment: "show_comment",
  },
  transaction: {
    createTransaction: "create_transaction",
    updatePayment: "update_payment",
    indexTransaction: "index_transaction",
    showUserCredit: "show_user_credit",
    updateTransaction: "update_transaction",
    deleteTransaction: "delete_transaction",
    checkoutPatientPayment: "checkout_patient_payment",
    getPurchaseInvoice: "get_purchase_invoice",
  },
  comment: {
    createCommentAssignee: "create_comment_assignee",
    showComment: "show_comment",
    indexComment: "index_comment",
    addComment: "create_comment",
    editComment: "update_comment",
    deleteComment: "delete_comment",
    showCommentAssignee: "show_comment_assignee",
    indexCommentAssignee: "index_comment_assignee",
    indexCommentAssignee: "index_comment_assignee",
    addAttachment: "create_comment_attachment",
    showCommentAttachment: "show_comment_attachment",
    downloadCommentAttachment: "download_comment_attachment",
    indexCommentAttachment: "index_comment_attachment",
    updateCommentAttachment: "update_comment_attachment",
    deleteAttachment: "delete_comment_attachment",
    createCommentWatcher: "create_comment_watcher",
    showCommentWatcher: "show_comment_watcher",
    indexCommentWatcher: "index_comment_watcher",
    deleteCommentWatcher: "delete_comment_watcher",
  },
  device: {
    indexDevice: "index_device",
    deleteDevice: "delete_device",
  },
  blog: {
    createBlogPost: "create_blog_post",
    updateBlogPost: "update_blog_post",
    updateBlogPostAttachment: "update_blog_post_attachment",
    deleteBlogPostAttachment: "delete_blog_post_attachment",
    showBlogPostAttachment: "show_blog_post_attachment",
    showBlogPost: "show_blog_post",
    indexBlogPost: "index_blog_post",
    uploadBlogPostCover: "upload_blog_post_cover",
    deleteBlogPostCover: "delete_blog_post_cover",
  },
  translator: {
    indexAppointmentTranslator: "index_appointment_translator",
    showAppointmentTranslator: "show_appointment_translator",
    createAppointmentTranslator: "create_appointment_translator",
    updateAppointmentTranslator: "update_appointment_translator",
    deleteAppointmentTranslator: "delete_appointment_translator",
  },
  contactUs: {
    indexContactUsRequests: "index_contact_us_requests",
    showContactUsRequest: "show_contact_us_request",
  },
  notification: {
    indexNotificationTrigger: "index_notification_trigger",
    showNotificationTrigger: "show_notification_trigger",
    createNotificationTrigger: "create_notification_trigger",
    updateNotificationTrigger: "update_notification_trigger",
    deleteNotification_trigger: "delete_notification_trigger",
    updateUserNotificationChannel: "update_user_notification_channel",
    indexUserNotificationChannel: "index_user_notification_channel",
    indexUserNotificationTrigger: "index_user_notification_trigger",
    updateUserNotificationTrigger: "update_user_notification_trigger",
  },
  template: {
    createTemplate: "create_template",
    updateTemplate: "update_template",
    deleteTemplate: "delete_template",
    indexTemplate: "index_template",
    sendTemplate: "send_template",
    uploadTemplateAttachment: "upload_template_attachment",
  },
};

export default accessList;
