import { React, useState, useEffect } from "react";
import "./style.scss";
import { Tabs, Button, Dropdown, Modal } from "antd";
import { AppstoreFilled, CloseCircleFilled } from "@ant-design/icons";
import Detile from "./overview";
import { useAssets } from "../../hooks/index";
import { fetchData } from "../../endpoints";
import Documents from "./document";
import Forms from "./form";
import Services from "./Services";
import { useLocation, useNavigate } from "react-router-dom";
import ReportManagement from "../Report";
import CommentPage from "../ToDo/comment/commentPage";
import access from "../../assets/access";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
const PatientPage = ({
  patient,
  setPatient,
  setIsOpen,
  loading,
  setLoading,
  isMobile,
  serviceTypes,
  getPatients,
  getServiceTypes,
  domain,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    const savedState = sessionStorage.getItem("modalState");
    if (savedState) {
      setModalData(JSON.parse(savedState));
      setIsModalVisible(JSON.parse(savedState).showModal);
      sessionStorage.removeItem("modalState");
    }
  }, []);
  const { getFile } = useAssets();
  const path = useLocation().pathname;
  const state = useLocation().state;
  const history = state ? state.previousPath : "/patients";
  const navigator = useNavigate();
  const getPatient = async () => {
    setLoading(true);
    var res = await fetchData(`admin/patient/${patient.id}`);
    if (res.status === 200) {
      setPatient({
        ...res.data.data,
        name: res.data.data.firstName + " " + res.data.data.lastName,
      });
    }

    setLoading(false);
  };
  const [navMobileItem, setNavMobileItem] = useState(
    path.includes("forms")
      ? 1
      : path.includes("services")
      ? 2
      : path.includes("documents")
      ? 3
      : path.includes("reports")
      ? 5
      : path.includes("Comments")
      ? 6
      : 0
  );
  const items = [
    {
      key: "0",
      label: (
        <button
          className={`menu-btn-mobile-item ${
            navMobileItem === 0 && "menu-btn-mobile-item-active"
          }`}
          onClick={() => setNavMobileItem(0)}
        >
          Overview
        </button>
      ),
    },

    privilage.indexOf(access.patientManegment.form.showForm) > -1 && {
      key: "1",
      label: (
        <button
          className={`menu-btn-mobile-item ${
            navMobileItem === 1 && "menu-btn-mobile-item-active"
          }`}
          onClick={() => {
            navigator("/patients/" + patient.id + "/forms");
            setNavMobileItem(1);
          }}
        >
          Form Submissions
        </button>
      ),
    },
    privilage.indexOf(access.patientManegment.service.indexService) > -1 &&
      privilage.indexOf(access.patientManegment.service.indexpurchase) > -1 && {
        key: "2",
        label: (
          <button
            className={`menu-btn-mobile-item ${
              navMobileItem === 2 && "menu-btn-mobile-item-active"
            }`}
            onClick={() => {
              navigator("/patients/" + patient.id + "/services/in-progress");
              setNavMobileItem(2);
            }}
          >
            Services
          </button>
        ),
      },
    privilage.indexOf(access.patientManegment.document.showDocument) > -1 && {
      key: "3",
      label: (
        <button
          className={`menu-btn-mobile-item ${
            navMobileItem === 3 && "menu-btn-mobile-item-active"
          }`}
          onClick={() => {
            navigator("/patients/" + patient.id + "/documents");
            setNavMobileItem(3);
          }}
        >
          Documents
        </button>
      ),
    },
    privilage.indexOf(access.patientManegment.report.index) > -1 && {
      key: "4",
      label: (
        <button
          className={`menu-btn-mobile-item ${
            navMobileItem === 4 && "menu-btn-mobile-item-active"
          }`}
          onClick={() => {
            navigator("/patients/" + patient.id + "/reports");
            setNavMobileItem(4);
          }}
        >
          Reports
        </button>
      ),
    },
    privilage.indexOf(access.patientManegment.comment.index) > -1 && {
      key: "5",
      label: (
        <button
          className={`menu-btn-mobile-item ${
            navMobileItem === 5 && "menu-btn-mobile-item-active"
          }`}
          onClick={() => {
            navigator("/patients/" + patient.id + "/Comments");
            setNavMobileItem(5);
          }}
        >
          Comments
        </button>
      ),
    },
  ];
  return (
    <div
      style={
        !isMobile
          ? { backgroundColor: "white", minHeight: "100vh" }
          : {
              backgroundColor: "white",
              minHeight: "100vh",
              paddingRight: "5%",
              paddingLeft: "5%",
            }
      }
      className="d-flex flex-column w-100"
    >
      <div
        style={
          isMobile
            ? { marginTop: "20px", marginLeft: "0.2rem" }
            : { marginTop: "5px", marginLeft: "15px" }
        }
        className="d-flex flex-row gap-2 align-items-center"
      >
        <svg
          onClick={async () => {
            navigator(history);

            if (history == "/patients") {
              setIsOpen(false);
              await getPatients();
            } else {
              var link = document.createElement("a");
              link.href = history;
              link.click();
              await getPatients({}, patient.userId);
            }
            await getServiceTypes();
          }}
          width={isMobile ? "20px" : "24px"}
          height="18"
          viewBox="0 0 24 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.7996 8.39557H2.65334L10.0208 1.01421C10.2528 0.78219 10.2528 0.40603 10.0208 0.174013C9.7889 -0.0580043 9.41285 -0.0580043 9.18091 0.174013L0.781469 8.57595C0.665495 8.68473 0.599609 8.83691 0.599609 8.99605C0.599609 9.15519 0.665498 9.30737 0.781469 9.41615L9.18091 17.8181C9.28965 17.9341 9.44178 18 9.60088 18C9.75997 18 9.91211 17.9341 10.0208 17.8181C10.1368 17.7093 10.2027 17.5571 10.2027 17.398C10.2027 17.2388 10.1368 17.0867 10.0208 16.9779L2.65334 9.59653H22.7996C23.131 9.59653 23.3996 9.32781 23.3996 8.99639C23.3996 8.66498 23.131 8.39625 22.7996 8.39625V8.39557Z"
            fill="#18324E"
          />
        </svg>
        <p
          style={{
            fontSize: "14px",
            fontWeight: "500",
            paddingTop: "10px",
            color: "#18324E",
          }}
        >
          Back to patients list
        </p>
      </div>
      <div
        style={
          isMobile
            ? { paddingLeft: "20px", alignItems: "center" }
            : { paddingLeft: "55px", alignItems: "center" }
        }
        className={`d-flex flex-row ${isMobile ? "gap-2" : " gap-4"}`}
      >
        <img
          src={patient.photo ? domain + patient.photo : getFile("Profile")}
          width={isMobile ? "70px" : "100px"}
          height={isMobile ? "70px" : "100px"}
          style={{ borderRadius: "45px" }}
        />
        <div
          className={`d-flex flex-column ${isMobile && "col-6"}`}
          style={{ justifyContent: "center" }}
        >
          <span
            style={
              isMobile
                ? { fontWeight: "700", fontSize: "12px", whiteSpace: "nowrap" }
                : { fontWeight: "700", fontSize: "20px" }
            }
          >
            {patient.name}
          </span>
          <span
            style={
              isMobile
                ? {
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "rgba(161, 129, 58, 1)",
                    marginTop: "0.2rem",
                  }
                : {
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgba(161, 129, 58, 1)",
                  }
            }
          >
            Patient
          </span>
        </div>
        {isMobile && (
          <Dropdown menu={{ items }} placement="bottomLeft">
            <button
              className="menu-btn-mobile"
              style={{ marginBottom: "40px" }}
            >
              <AppstoreFilled style={{ fontSize: "20px" }} />
            </button>
          </Dropdown>
        )}
      </div>{" "}
      {isMobile && <hr></hr>}
      <div
        style={
          isMobile
            ? { width: "100%", marginTop: "10px" }
            : { width: "93%", marginTop: "-20px" }
        }
        className="top-tabs d-flex flex-row mx-4 "
        dir="rtl"
      >
        {!isMobile && (
          <Tabs
            onTabClick={(activeKey) => {
              var tab =
                activeKey == 4
                  ? ""
                  : activeKey == 2
                  ? "forms"
                  : activeKey == 0
                  ? "documents"
                  : activeKey == 5
                  ? "reports"
                  : activeKey == 6
                  ? "Comments"
                  : "services";
              if (tab == "services") {
                navigator("/patients/" + patient.id + "/services/in-progress");
              } else {
                navigator("/patients/" + patient.id + "/" + tab);
              }
            }}
            size={isMobile ? "small" : "middle"}
            tabBarGutter={18}
            style={{ color: "black", fontWeight: "500", width: "97%" }}
            defaultActiveKey={
              path.includes("forms")
                ? 2
                : path.includes("services")
                ? 1
                : path.includes("documents")
                ? 0
                : path.includes("reports")
                ? 5
                : path.includes("Comments")
                ? 6
                : 4
            }
            tabPosition="top"
            direction="rtl"
            type="card"
            items={[
              privilage.indexOf(access.patientManegment.document.showDocument) >
                -1 && {
                label: `Documents`,
                key: 0,
                children: <Documents patientId={patient.id} />,
              },

              privilage.indexOf(access.patientManegment.service.indexService) >
                -1 && {
                label: `Services`,
                key: 1,
                children: (
                  <Services
                    patientId={patient.id}
                    name={patient.firstName + " " + patient.lastName}
                    mrn={patient.clinicalCode}
                    patient={patient}
                  />
                ),
              },
              privilage.indexOf(access.patientManegment.form.showForm) > -1 && {
                label: `Form Submissions`,
                key: 2,
                children: <Forms patientId={patient.id} />,
              },
              {
                label: `Overview`,
                key: 4,
                children: (
                  <Detile
                    patient={{
                      ...patient,
                      birthday: new Date(patient.birthday),
                    }}
                    getPatient={getPatient}
                    loadingPage={loading}
                    serviceTypes={serviceTypes}
                    domain={domain}
                  />
                ),
              },
              privilage.indexOf(access.patientManegment.report.index) > -1 && {
                label: "Reports",
                key: 5,
                children: (
                  <ReportManagement patientId={patient.id} patient={patient} />
                ),
              },
              privilage.indexOf(access.patientManegment.comment.index) > -1 && {
                label: "Comments",
                key: 6,
                children: (
                  <CommentPage patientId={patient.id} patient={patient} />
                ),
              },
            ]}
          />
        )}
      </div>
      {isMobile && (
        <div>
          {navMobileItem === 0 && (
            <Detile
              patient={{
                ...patient,
                birthday: new Date(patient.birthday),
              }}
              getPatient={getPatient}
              loadingPage={loading}
              serviceTypes={serviceTypes}
              domain={domain}
            />
          )}
          {navMobileItem === 1 && <Forms patientId={patient.id} />}
          {navMobileItem === 2 && (
            <Services
              patientId={patient.id}
              name={patient.firstName + " " + patient.lastName}
              mrn={patient.clinicalCode}
              patient={patient}
            />
          )}
          {navMobileItem === 3 && <Documents patientId={patient.id} />}
          {navMobileItem === 4 && (
            <ReportManagement patientId={patient.id} patient={patient} />
          )}
          {navMobileItem === 5 && (
            <CommentPage patientId={patient.id} patient={patient} />
          )}
        </div>
      )}
      <Modal
        visible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false);
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        closeIcon={null}
      >
        <div className="d-flex flex-row align-items-center gap-2">
          <CloseCircleFilled style={{ color: "#ff4d4f" }} />
          <p style={{ fontWeight: "700", margin: "0" }}>
            {modalData?.message || "Something went wrong!"}
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default PatientPage;
