import React from "react";
import "./styles.scss";
import { useState, useRef, useCallback } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, Button } from "antd";
import ReactQuill, { Quill } from "react-quill";

function ChatBubbles({
  sender = false,
  message,
  time,
  Delete,
  Edit,
  name,
  subject = "",
  id,
}) {
  const quillRef = useRef(null);
  const [modal, contextHolder] = Modal.useModal();
  const [ishover, setishover] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [text, setText] = useState(message);
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(24, 50, 78, 0.9)",
      zIndex: 5,
    },
  };
  var date = new Date(time);
  var minutes = date.getMinutes();
  var hour = date.getHours();
  if (hour < 10) hour = "0" + hour;
  if (minutes < 10) minutes = "0" + minutes;

  const modules = {
    toolbar: {
      container: [[{ header: "1" }, { header: "2" }]],
    },
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
  ];

  const handleChange = useCallback((html) => {
    setText(html);
  }, []);

  return sender ? (
    <>
      <div className="body">
        <p
          className="send"
          onMouseEnter={() => {
            setishover(true);
          }}
          onMouseLeave={() => {
            setishover(false);
          }}
        >
          {subject.length > 0 && (
            <div style={{ fontWeight: "bold", marginBottom: "8px" }}>
              {subject}
            </div>
          )}
          {isEdit ? (
            <div>
              {/* <textarea
                defaultValue={text}
                style={{
                  backgroundColor: "inherit",
                  border: "none",
                  resize: "none",
                  outline: "none",
                }}
                rows={5}
                onChange={(e) => {
                  setText(e.target.value);
                }}
              ></textarea> */}

              <div style={{ width: "220px", marginBottom: "10px" }}>
                <ReactQuill
                  className="message-input"
                  ref={quillRef}
                  value={text}
                  formats={formats}
                  modules={modules}
                  onChange={handleChange}
                />
              </div>
              <Button
                type="primary"
                onClick={async () => {
                  await Edit({ id: id, subject: subject, message: text });
                  setisEdit(false);
                }}
              >
                Submit
              </Button>
            </div>
          ) : message[0] == "<" && message.slice(-1) == ">" ? (
            <div dangerouslySetInnerHTML={{ __html: message }} />
          ) : (
            message
          )}

          <span className="mt-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              fill="currentColor"
              class="bi bi-pencil"
              viewBox="0 0 16 16"
              style={
                ishover
                  ? { marginRight: "5px", cursor: "pointer" }
                  : { visibility: "hidden", marginRight: "5px" }
              }
              onClick={() => {
                setisEdit(!isEdit);
              }}
            >
              <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              fill="currentColor"
              class="bi bi-trash"
              viewBox="0 0 16 16"
              style={
                ishover
                  ? { marginRight: "5px", cursor: "pointer" }
                  : { visibility: "hidden", marginRight: "5px" }
              }
              onClick={() => {
                modal.confirm({
                  title: "are you sure to delete this message?",
                  icon: <ExclamationCircleOutlined />,
                  okText: "sure",
                  cancelText: "cancel",
                  onOk: async () => {
                    setisLoading(true);
                    await Delete({ id: id });
                    setisLoading(false);
                    setIsOpenDelete(false);
                  },
                });
              }}
            >
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
            </svg>
            {hour + ":" + minutes}
            <svg
              width="13"
              height="8"
              viewBox="0 0 13 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginLeft: "5px" }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.85757 5.6415L8.49908 0L9.3576 0.858539L3.28684 6.92929C3.17299 7.04312 3.01858 7.1071 2.85757 7.1071C2.69656 7.1071 2.54215 7.04312 2.4283 6.92929L0 4.50099L0.858533 3.64246L2.85757 5.6415Z"
                fill="#9E9E9E"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.50017 5.64155L12.1414 0L13 0.858514L6.9295 6.92928C6.69249 7.16634 6.30815 7.16634 6.07103 6.92934L5.16016 6.01872L6.01858 5.16008L6.50017 5.64155Z"
                fill="#9E9E9E"
              />
            </svg>
          </span>
        </p>
      </div>
      {contextHolder}
    </>
  ) : (
    <div className="body">
      <p className="receive">
        <h3 style={{ color: "#0084c7", fontSize: "12px", fontWeight: "bold" }}>
          {name}
        </h3>
        {subject.length > 0 && (
          <div style={{ fontWeight: "bold", marginBottom: "8px" }}>
            {subject}
          </div>
        )}

        {message[0] == "<" && message.slice(-1) == ">" ? (
          <div dangerouslySetInnerHTML={{ __html: message }} />
        ) : (
          message
        )}

        <span className="mt-3"> {hour + ":" + minutes}</span>
      </p>
    </div>
  );
}
export default ChatBubbles;
