import React, { useState, useRef, useEffect } from "react";
import { useMobile } from "../../hooks";
import { Input, Button, Space, Table, Modal, Form, Select, Tag } from "antd";
import Highlighter from "react-highlight-words";
import ReactQuill from "react-quill";
import "./styles.scss";
import {
  SearchOutlined,
  PlusOutlined,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { postData, fetchData, putData, deleteData } from "../../endpoints";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 22,
  },
};

var modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

var formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];
function EmailTemplatePage() {
  const [modal, contextHolder] = Modal.useModal();
  const isMobile = useMobile();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [data, setdata] = useState([]);
  const [template, setTemplate] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [Openaddtemplate, setOpenaddtemplate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [html, setHtml] = useState("");
  const searchInput = useRef(null);

  const fetchEmailsTemplates = async (page = 1, reload = false) => {
    setIsLoading(true);
    var temp = data;
    if (reload) {
      temp = [];
    }
    if (data.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(`admin/template?perPage=15&page=` + page);
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setdata(
            temp
              .filter((a, i) => {
                return a.category;
              })
              .concat(res.data.data)
          );
        } else {
          setdata(
            temp
              .filter((a, i) => {
                return a.category;
              })
              .concat([...res.data.data])
          );
        }
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchEmailsTemplates(1);
  }, []);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {}
            }
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    setIsLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      setdata([]);
      //   await fetchEvents(1, true);
    } else {
      //   let search = "";
      //   if (dataIndex == "title") search = "title";
      //   if (dataIndex == "description") search = "description";
      //   if (dataIndex == "code") search = "code";
      //   var res = await fetchData(
      //     `admin/appointment-event/?${search}=` + selectedKeys[0]
      //   );
      //   res.data.data.map((tempdata, index) => {
      //     tempdata["key"] = index;
      //   });
      //   if (res.status == 200) {
      //     setdata(res.data.data);
      //   }
    }
    setIsLoading(false);
  };

  const handleReset = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    setdata([]);
    // await fetchEvents(1, true);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "category",
      key: "category",
      //   width: "15%",
      // ...getColumnSearchProps("category"),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      // ...getColumnSearchProps("subject"),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record) => new Date(record).toDateString(),
      // ...getColumnSearchProps("createdAt"),
    },

    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <a
          style={{ color: "#2E6CDF", textDecoration: "none" }}
          href={`/send-email?templateId=${u.id}&template=${u.subject}`}
          target="_blank"
        >
          Send to
        </a>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <a
          style={{ color: "black" }}
          onClick={() => {
            // console.log(u.message)
            setIsEdit(true);
            setTemplate(u);
            setHtml(u.message);
            setOpenaddtemplate(true);
          }}
        >
          Edit
        </a>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <a
          style={{ color: "#BC2323" }}
          onClick={() => {
            modal.confirm({
              content: "Are you sure you want to delete this email template?",
              icon: <ExclamationCircleOutlined />,
              
              okText: "Confirm",
              cancelText: "Cancel",
              onOk: async () => {
                var res = await deleteData(`admin/template/${u.id}`);
                if (res.status == 200) {
                  Modal.success({
                    content: "Email template deleted successfully",
                  });
                }
                fetchEmailsTemplates(1, true);
              },
            });
          }}
        >
          Delete
        </a>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <div
        style={
          isMobile
            ? { width: "87vw", padding: "0px 20px" }
            : { padding: "80px 40px", minHeight: "100vh" }
        }
      >
        <p
          style={{
            padding: "0px 0px 24px 0px",
            fontWeight: "700",
            fontSize: "20px",
          }}
        >
          Email Templates
        </p>

        <Table
          scroll={{ x: "auto" }}
          loading={isLoading}
          columns={columns}
          dataSource={data}
          pagination={{
            onChange: async (page) => {
              await fetchEmailsTemplates(page);
            },
            // total: 50,
            pageSize: 15,
          }}
          footer={() => {
            return (
              <div
                onClick={() => {
                  setOpenaddtemplate(true);
                }}
                className="d-flex fle-row gap-2 px-4 align-items-center"
                style={{ cursor: "pointer" }}
              >
                <PlusOutlined
                  style={{
                    color: "rgba(46, 108, 223, 1)",
                  }}
                />
                <h6
                  style={{
                    color: "rgba(46, 108, 223, 1)",
                    marginBottom: "0",
                  }}
                >
                  Add Template{" "}
                </h6>
              </div>
            );
          }}
        />
      </div>
      <Modal
        open={Openaddtemplate}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => {
          setOpenaddtemplate(false);
          setIsEdit(false);
          setTemplate({});
          form.resetFields();
          fetchEmailsTemplates(1, true);
        }}
        width={isMobile ? "90vw" : "65vw"}
        destroyOnClose
        afterOpenChange={(isOpen) => {
          if (isOpen) {
            form.setFieldsValue(
              isEdit
                ? {
                    category: template.category,
                    subject: template.subject,
                    senders: template.senders,
                    message: template.message,
                  }
                : {
                    category: "",
                    subject: "",
                    senders: "",
                    message: "",
                  }
            );
          }
        }}
      >
        <p
          style={{
            fontWeight: "700",
            fontSize: "30px",
            textAlign: "center",
            marginBottom: "15px",
          }}
        >
          {isEdit ? "Edit" : "Add"} Template
        </p>
        <hr
          style={{ marginBottom: "25px", marginTop: "-10px", width: "100%" }}
        />

        <Form
          {...layout}
          form={form}
          name="control-hooks"
          style={{
            maxWidth: "90%",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
          }}
          onFinish={async (data) => {
            setIsLoading(true);
            if (isEdit) {
              data = { ...data, senders: [data.senders], provider: "email" };
              var res = await putData(`admin/template/${template.id}`, data);
              if (res.status == 200) {
                setOpenaddtemplate(false);
                setIsEdit(false);
                setTemplate({});
                form.resetFields();
                fetchEmailsTemplates(1, true);
              }
            } else {
              data = { ...data, senders: [data.senders], provider: "email" };
              var res = await postData("admin/template", data);
              form.resetFields();
              setOpenaddtemplate(false);
              fetchEmailsTemplates(1, true);
            }
            setIsLoading(false);
          }}
        >
          <Form.Item
            name="category"
            label="Title"
            style={{ marginBottom: "15px" }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="subject"
            label="Email subject"
            style={{ marginBottom: "15px" }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="senders"
            label="Sender Email"
            style={{ marginBottom: "15px" }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select options={[{key:'info@allbrainsclinic.com',value:'info@allbrainsclinic.com'}]}/>
          </Form.Item>
          <Form.Item
            name="message"
            label="Message"
            rules={[
              {
                required: true,
                message: "Please input the message!",
              },
            ]}
          >
            <ReactQuill formats={formats} modules={modules} />
          </Form.Item>
        </Form>

        <div
          style={{ textAlign: "center", marginTop: "15px" }}
          onClick={() => {
            form.submit();
          }}
        >
          <Button type="primary" size="large" loading={isLoading}>
            Save
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default EmailTemplatePage;
